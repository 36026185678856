import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { getExhibitionAction, postExhibitionAction, putExhibitionAction } from '../../../../actions/exhibitionsActions';
import { useGlobalContext } from '../../../context/GlobalContext';
import styles from './EditExhibition.module.scss';
import { MESSAGE_EXHIBITIONS } from '../../../../actions/types';
import DescriptionForm from './Form/DescriptionForm';
import { useWindowSize } from '../../../../utils/useWindowSize';
import BadgesForm from './Form/BadgesForm';

export default function EditExhibition({ setActivePage  }) {
  const [ context , dispatch ] = useGlobalContext();
  const { id } = useParams();
  const [width] = useWindowSize();
  const { exhibition, message }  = context.exhibitions;
  const { auth } = context;
  const { user } = auth;

  const containerRef = useRef();
  const rect = containerRef?.current?.getBoundingClientRect();

  const [ step , setStep] = useState();
  const [form, setForm ] = useState({
    contact: {},
    showroomType:"linear",
  });

  const getData = useCallback(() => {
    setActivePage("exhibition");
    setStep(0);
    if (id) {
      getExhibitionAction(dispatch ,id);
    }
  }, [dispatch, setActivePage, id]);


  function handleChangeForm(obj) {
    setForm({...form, ...obj});
  } 

  function submit() {
    if(!exhibition?._id) {
      return postExhibitionAction(dispatch, {...form, user: user?._id });
    }
    return putExhibitionAction(dispatch, exhibition?._id,  form );
  }

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (exhibition) {
      setForm(exhibition);
    }
  },[exhibition, width]);

  useEffect(() => {
    if (message) {
      store.addNotification({
        message,
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      dispatch({
        type : MESSAGE_EXHIBITIONS,
        payload : null,
      });
    }
  },[dispatch, message]);


  return (
    <div ref={containerRef} className={styles.container}>

      <header className={styles.header}>
        <h1>{exhibition?.name || 'Nouveau salon'}</h1>
        <div className={styles.actions}>
          <div className={styles.steps}>
            <button
              type="button"
              onClick={() => setStep(0)}
              className={`${styles.link} ${step === 0 ? styles.active : ''}`}
            >
              Description
            </button>
            <button
              type="button"
              onClick={() => setStep(1)}
              className={`${styles.link} ${step === 1 ? styles.active : ''}`}
            >
              Badges
            </button>
          </div>
          <button
            type="button"
            className={styles.submit}
            onClick={() => submit()}
          >
            Enregistrer
          </button>
        </div>
      </header>

      <main className={styles.content}>
        {containerRef.current && step >= 0 &&
          <div className={styles['steps-container']} style={{ width: rect?.width * 2, transform: `translateX(-${step * rect?.width}px)` }}>
            <DescriptionForm
              form={form}
              handleChangeForm={handleChangeForm}
              width={rect?.width}
            />
            <BadgesForm
              form={form}
              handleChangeForm={handleChangeForm}
              width={rect?.width}
            />
          </div>
        }
      </main>

    </div>
  )
}
