import React from 'react';
import filterIcon from "../../../assets/images/icons/filters.svg";

export default function SortItem({ item , isAsc , setActiveSort , styles }) {
  return (
    <div className={`${styles.col} ${styles['sort-item']} ${item.className ?styles[item.className] :''}`} onClick={() =>setActiveSort({ ...item, isAsc: !isAsc})}>
      <span>{item.label}</span>
      <img src={filterIcon} className={isAsc? styles.active : ""} alt="filter"/>
    </div>
  )
}
