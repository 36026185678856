import {
  GET_OFFERS_LIST,
  ERROR_OFFERS,
  SCROLL_ON_OFFERS,
  GET_OFFER,
  MESSAGE_OFFERS
} from "../actions/types"

const initialState = {
  offersList: [],
  offer: {},
  error: {},
  scrolloffers: 0,
  message: null,
};

export default function offersReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_OFFERS:
      return {...state, message : action.payload}
    case GET_OFFER:
      return {...state, offer : action.payload}
    case GET_OFFERS_LIST:
      return {...state, offersList : action.payload}
    case ERROR_OFFERS:
        return {...state, error: action.payload}
    case SCROLL_ON_OFFERS:
      return {...state, scrollOffers: action.payload}
    default:
      return {...state};
  }
}
