import { getData , getFile } from './index';
import * as FileSaver from 'file-saver';

import {
  GET_RECEPTION_LIST,
  GET_BADGE,
  ERROR_RECEPTION,
} from "./types"

export async function getReceptionListAction(dispatch , event){
  let url = `/user/?date=${event}`
  let receptionList;
  await getData( ERROR_RECEPTION, url, dispatch , true).then((response)=>{
    receptionList = response.users
  })
  
  dispatch({
    type : GET_RECEPTION_LIST,
    payload : receptionList
  })
}

export async function getBadgeFileAction(dispatch , user , eventName ){
  let url = "/user/badge/" + user._id
  let badge;
  await getFile( ERROR_RECEPTION, url, dispatch , true).then((response)=>{
    badge = response;
    var blob = new Blob([badge], {type: "data:application/pdf"});
    console.log(eventName);
    FileSaver.saveAs(blob, `badge-${user.profile.lastName}-${eventName}.pdf`);
  })
};

export async function getBadgeAction(dispatch , id ){
  let url = "/user/qrcode/" + id
  let badge;
  await getData( ERROR_RECEPTION, url, dispatch , true).then((response)=>{
    badge = response.code;
  });
  dispatch({
    type : GET_BADGE,
    payload : badge
  })
}