import {
  GET_SHOPS_LIST,
  ERROR_SHOPS,
  GET_SHOP,
  MESSAGE_SHOPS,
  SCROLL_ON_SHOPS,
  EXPORT_SHOPS_LIST
} from "../actions/types"

const initialState = {
  shopsList: null,
  shop: {},
  error: {},
  message: null,
  scrollPos: 0,
  exportShopsList: {}
};

export default function shopsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SHOPS_LIST:
      return {...state, shopsList : action.payload}
    case ERROR_SHOPS:
        return {...state, error: action.payload}
    case GET_SHOP:
      return {...state, shop: action.payload}
    case MESSAGE_SHOPS:
      return {...state, message: action.payload}
    case SCROLL_ON_SHOPS:
      return {...state, scrollPos: action.payload}
    case EXPORT_SHOPS_LIST:
      return {...state, exportShopsList: action.payload}
    default:
      return {...state};
  }
}
