import React, { useState, useEffect } from 'react';
import Switch from "react-switch";

export default function SwitchField(props){
  const [ checked, setChecked ] = useState({ checked : props.checked })

  useEffect(()=>{
    //IF STATE CHECKED IS MANAGE FROM PARENT COMPONENT
    setChecked(props.checked ? {checked : props.checked } : {checked : false})
  },[props.checked])

  function handleChange(checked) {
    if(props.handleChange){
      //IF STATE CHECKED IS MANAGE FROM PARENT COMPONENT
      props.handleChange(checked)
    }else{
      //IF STATE CHECKED IS MANAGE INSIDE COMPONENT
      setChecked({ checked })
    }
  }

  return (
    <div className="switch-field">
       <div className="switch">
        <Switch onChange={handleChange} checked={checked.checked || false} />
       </div>
       <div>
        {props.children}
        {props.conditional}
       </div>
     </div>
  )
}
