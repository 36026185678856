import { postData , getData } from './index';

import {
  IS_AUTH,
  USER_AUTH,
  ERROR_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_DASHBOARD,
  SET_ACTIVE_EXHIBITION
} from "./types"

export async function loginAction(dispatch , obj){
  let url = "/auth/login";
  var login;

  await postData(IS_AUTH , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    login = response
  });

  if (login.user.role === "Admin" || login.user.role === "Accueil") {
    localStorage.setItem("token" , login.token);
    const exhibition = login.user?.exhibitions?.length > 0 && login.user.exhibitions[0]?._id;

    if (!localStorage.getItem("exhibition") && exhibition) {
      localStorage.setItem("exhibition" , exhibition);
    }
    dispatch({
      type : IS_AUTH,
      payload : true
    });
    dispatch({
      type : SET_ACTIVE_EXHIBITION,
      payload : exhibition
    });
  }
};

export async function forgotPasswordAction(dispatch , obj){
  let url = "/auth/forgot-password";
  var mailSend;

  await postData(FORGOT_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    mailSend = response
  });

  dispatch({
    type : FORGOT_PASSWORD,
    payload : mailSend
  });
};

export async function resetPasswordAction(dispatch , obj){
  let url = "/auth/reset-password/" + obj.token;
  var reset;

  await postData(RESET_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    reset = response
  });
  
  dispatch({
    type : RESET_PASSWORD,
    payload : reset
  });
};

export async function getUserAction(dispatch){
  let url = "/profile";
  var user;

  await getData( ERROR_AUTH, url, dispatch, true , false ).then((response)=>{
    user = response.user;
  });

  dispatch({
    type : ERROR_AUTH,
    payload : {}
  });

  dispatch({
    type : USER_AUTH,
    payload : user
  });
  const exhibition = localStorage.getItem("exhibition");
  if (exhibition) {
    dispatch({
      type : SET_ACTIVE_EXHIBITION,
      payload : exhibition
    });
  }
};

export async function getDashbordAction(dispatch){
  let url = "/company/dashboard";
  var dashbord;
  dispatch({
    type: GET_DASHBOARD,
    payload: null,
  });

  await getData( ERROR_AUTH, url, dispatch, true ).then((response)=>{
    dashbord = response;
  });
  dispatch({
    type : ERROR_AUTH,
    payload : {}
  });
  dispatch({
    type : GET_DASHBOARD,
    payload : dashbord
  });
};
