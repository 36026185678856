import React, { useEffect, useState } from 'react';
// Require Editor CSS files.
import config from './config';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import './HtmlEditor.scss';

export default function HtmlEditor({ defaultValue, onChange }) {
  const [editorValue, setEditorValue ]= useState();
  function onEditorStateChange(state) {
    setEditorValue(state);
    let html  = draftToHtml(convertToRaw(editorValue?.getCurrentContent()));
    onChange(html);
  };

  useEffect(() => {
    if (defaultValue) {
      const blocksFromHtml = htmlToDraft(defaultValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorValue(editorState);
    } else {
      setEditorValue(EditorState.createEmpty());
    }
  },[defaultValue]);

  return (
    <div>
      <Editor
        editorState={editorValue}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={config}
      />
    </div>
  )
}
