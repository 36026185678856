import React, { useState } from 'react';

import Loader from "../Loader";

import closeImg from '../../../assets/images/icons/close.svg';

import style from './UploadOfferModal.module.scss';

export default function UploadOfferModal({ type , label , close , onSubmit }) {
  const id = `file-${type}`;
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    setIsLoading(true);
    await onSubmit(formData);
    setIsLoading(false);
    close();
  }

  return (
    <div className={style.container}>
      <button
        type="button"
        className={style.close}
        onClick={() => close()}
      >
        <img src={closeImg} alt="close-img" />
      </button>
      <h2>{label}</h2>
      {!isLoading ?
        <>
          <input
            id={id}
            className={style.input}
            type="file"
            onChange={(e) => setFile(e?.target?.files[0])}
          />
          <label
            htmlFor={id}
            className={style.btn}
          >
            <p>{file?.name || "Ajouter un fichier"}</p>
          </label>
          {file &&
            <button
              type='button'
              className={`${style.btn} ${style.success}`}
              onClick={() => handleSubmit()}
            >
              Valider
            </button>
          }
        </>
      :
        <div>
          <Loader/>
        </div>
      } 
    </div>
  )
}
