import React , { useCallback , useEffect , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';


import { useGlobalContext } from '../../context/GlobalContext';
import { getEditListsAction } from '../../../actions/providersActions';
import { getShopAction, getShopsListAction } from '../../../actions/shopsActions';

import shopsIcon  from "../../../assets/images/icons/shops_icon.svg";
import ContactRow from '../../Partials/ContactRow';

export default function ShopContactsEdit(props) {

  const [ context , dispatch ] = useGlobalContext();

  const [ addUserActive , setAddUserActive ] = useState(false);

  var shop = context.shops.shop;
  var lists = context.providers.lists;
  const companiesOptions = context?.shops?.shopsList || [];

  const getShop = useCallback(() => {
    getShopAction( dispatch, props.match.params.id);
    getShopsListAction(dispatch);
    dispatch({
      type : 'MESSAGE_USERS',
      payload : ""
    });
    dispatch({
      type:'ERROR_USERS',
      payload: null
    })
  }, [dispatch , props.match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    props.setActivePage("shops");
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (addUserActive) {
      var el = document.getElementById("add-user");
      el.scrollIntoView();
    }
  }, [addUserActive])

  useEffect(() => {
    getEditLists();
    if(!shop.company) {
      getShop();
    }
    // eslint-disable-next-line
  }, [getShop, getEditLists , shop]);

  useEffect(() => {
    if (context.users.message === "User successfully updated!") {
      successUpdate();
      getShop();
    }
    if (context.users.message === "user register !") {
      setAddUserActive(false)
      successCreate();
      getShop();
    }
    if (context.users.message === "User successfully removed!") {
      successRemove();
      getShop();
    }
    // eslint-disable-next-line
  }, [context.users.message]);

  function successRemove() {
    store.addNotification({
      message: "Utilisateur supprimé",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function successUpdate() {
    store.addNotification({
      message: "Utilisateur mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  function successCreate() {
    store.addNotification({
      message: "Utilisateur inscrit!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  function renderShopContacts() {
    if(shop.users && lists.activities) {
      var companyId = shop?.company?._id;
      const daysOptions = lists?.days;
      const activitiesOptions = lists.activities.map(domain => ({ label: domain , value: domain }));
      return (
        <>
          <header className="page-header">
            <div className="icon">
              <img src={shopsIcon} alt="icon-provider"/>
            </div>
            <h1>{shop.company.name}</h1>
          </header>
          <main className="page-content">
            <Link to={"/shop-view/" + companyId } className="btn-edit mb-10">
              <button className="btn-edit" style={{ width : 100}}>Retour</button>
            </Link>

            {shop.users.filter(user => user._id !== context.auth?.user?._id).map(( contact ,i ) => <ContactRow 
              key={i}
              companyId={companyId}
              contact={contact}
              dispatch={dispatch}
              error={context.users.error}
              daysOptions={daysOptions}
              contactType="shop"
              companiesOptions={companiesOptions}
             />)}

            {addUserActive ?
              <div id="add-user">
                <ContactRow 
                  companyId={companyId}
                  addUserActive={addUserActive}
                  addCompany={{ value: shop?.company?._id , label: shop.company.name }}
                  setAddUserActive={setAddUserActive}
                  error={context.users.error}
                  daysOptions={daysOptions}
                  dispatch={dispatch}
                  contactType="shop"
                  companiesOptions={companiesOptions}
                  activitiesOptions={activitiesOptions} />
              </div>
              :            
              <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
                <button
                  className={"btn-secondary"}
                  onClick={() => setAddUserActive(true)}
                  >
                    <Translate id="btn.addaUser"/>
                </button>
              </div>
            }

          </main>
        </>
      )
    }
  }

  return (
    <div className="page-container">
      {renderShopContacts()}
    </div>
  )
}
