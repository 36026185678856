import {
  GET_EXHIBITIONS_LIST,
  ERROR_EXHIBITIONS,
  GET_EXHIBITION,
  MESSAGE_EXHIBITIONS,
  SCROLL_ON_EXHIBITIONS,
  EXPORT_EXHIBITIONS_LIST,
  PUT_EXHIBITION,
  POST_EXHIBITION,
  SET_ACTIVE_EXHIBITION,
  SET_EXHIBITION_DAYS,
} from "../actions/types"

const initialState = {
  exhibitionsList: null,
  exhibition: null,
  activeExhibition: null,
  daysOptions: null,
  error: {},
  message: null,
  scrollPos: 0,
  exportExhibitionsList: {}
};

export default function exhibitionsReducer(state = initialState, action) {
  const list = state.exhibitionsList ? [...state.exhibitionsList] : [];
  const index = list?.findIndex((ex) => ex?._id === action.payload?._id);
  switch (action.type) {
    case GET_EXHIBITIONS_LIST:
      return {...state, exhibitionsList : action.payload }
    case SET_EXHIBITION_DAYS:
      return {...state , daysOptions: action.payload }
    case SET_ACTIVE_EXHIBITION:
      return {...state , activeExhibition: action.payload }
    case ERROR_EXHIBITIONS:
        return {...state, error: action.payload}
    case POST_EXHIBITION:
      return {...state, exhibition: action.payload , message: "Le salon a été crée"}
    case PUT_EXHIBITION:
      if (index !== -1) {
        list[index] = action.payload;
      }
      return {...state, exhibition: action.payload, exhibitionsList: list, message: "Le salon a été modifié"}
    case GET_EXHIBITION:
      return {...state, exhibition: action.payload}
    case MESSAGE_EXHIBITIONS:
      return {...state, message: action.payload}
    case SCROLL_ON_EXHIBITIONS:
      return {...state, scrollPos: action.payload}
    case EXPORT_EXHIBITIONS_LIST:
      return {...state, exportExhibitionsList: action.payload}
    default:
      return {...state};
  }
}
