import { getData , postData } from './index';

import {
  GET_ORDERS_LIST,
  ERROR_ORDERS,
  MESSAGE_ORDERS,
  GET_PRODUCTS_LIST,
  GET_ORDER,
  EXPORT_ORDERS,
  VALIDATE_ORDER
} from "./types"

export async function getOrdersListAction(dispatch){
  let url = "/order"
  let offersList;
  await getData( ERROR_ORDERS, url, dispatch , true).then((response)=>{
    offersList = response.orders
  })
  dispatch({
    type : GET_ORDERS_LIST,
    payload : offersList
  })
}

export async function getProductsAction(dispatch){
  let url = "/product"
  let products;
  await getData( ERROR_ORDERS, url, dispatch , true).then((response)=>{
    products = response.products
  })
  dispatch({
    type : GET_PRODUCTS_LIST,
    payload : products
  })
}


export async function postOrderAction(dispatch , obj ){
  let url = "/order"
  let createOrder;
  await postData(MESSAGE_ORDERS , ERROR_ORDERS , url, dispatch , obj, true ).then((response)=>{
    createOrder = response
  });
  dispatch({
    type : MESSAGE_ORDERS,
    payload : createOrder
  })
}

export async function getOrderAction(dispatch , id){
  let url = "/order/" + id 
  let offer;
  await getData( ERROR_ORDERS, url, dispatch , true).then((response)=>{
    offer = response.order
  })
  dispatch({
    type : GET_ORDER,
    payload : offer
  })
}

export async function exportOrdersAction(dispatch , obj ){
  let url = "/export/order"
  let exportList;
  await postData(EXPORT_ORDERS , ERROR_ORDERS , url, dispatch , obj, true ).then((response)=>{
    exportList = response
  });
  dispatch({
    type : EXPORT_ORDERS,
    payload : exportList
  })
}

export async function validateOrdersAction(dispatch , obj ){
  let url = "/order/validate"
  let validate;
  await postData(VALIDATE_ORDER , ERROR_ORDERS , url, dispatch , obj, true ).then((response)=>{
    validate = response
  });
  dispatch({
    type : VALIDATE_ORDER,
    payload : validate
  })
}

