import React , { useEffect , useCallback , useState } from 'react';
import { Link } from "react-router-dom";
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { useGlobalContext } from '../../context/GlobalContext';
import { getAnimationsListAction } from '../../../actions/animationsActions';

import '../../../styles/List.scss';

import AnimationRow from './Animations_Partials/AnimationRow';
import Loader from "../../Partials/Loader";

import filterIcon from "../../../assets/images/icons/filters.svg";
import searchIcon from "../../../assets/images/icons/search.svg";

export default function AnimationsList(props) {
  props.setActivePage("animations");

  const [ context, dispatch ] = useGlobalContext();
  const [ search , setSearch ] = useState("");

  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortBySize , setSortBySize ] = useState(false);
  const [ sortByStatut , setSortByStatut ] = useState(false);

  const [ filtredList , setFiltredList ] = useState();

  const getAnimationsList = useCallback(() => {
    getAnimationsListAction(dispatch);
    dispatch({
      type : 'GET_ANIMATION',
      payload : {}
    });
    dispatch({
      type : 'SCROLL_ON_STANDS',
      payload : 0
    });
    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });
  }, [dispatch]);

  useEffect(() => {
      getAnimationsList();
  }, [getAnimationsList]);

  useEffect(() => {
    window.scrollTo(0, context.animations.scrollAnimations);
  });

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : -currPos.y
    });
  });

  useEffect(() => {

    var animations = context.animations.animationsList.map(anim => {
      if(anim.dimensionL && anim.dimensionP) anim.dimension = anim.dimensionL * anim.dimensionP;
      return anim ;
    });

    animations.sort(function(a, b) {
      var dateA = new Date(a.updatedAt)
      var dateB = new Date(b.updatedAt)
      return dateA.getTime() - dateB.getTime()
    }).reverse();

    setFiltredList(animations);

  }, [context.animations.animationsList]);

  useEffect(() => {
    setFiltredList(context.animations.animationsList.filter(anim => anim.company.name.toLowerCase().includes(search.toLowerCase()) || anim.description.toLowerCase().includes(search.toLowerCase())));
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> ( a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> ( a.company.name > b.company.name)? 1:-1).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortBySize) {
        setFiltredList(sortByDimension(newList));
      } else {
        setFiltredList(sortByDimension(newList).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortBySize]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByStatut) {
        setFiltredList(newList.sort(triAnimStatut));
      } else {
        setFiltredList(newList.sort(triAnimStatut).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByStatut]);

  function triAnimStatut(a,b)  {
    return (a.isValid > b.isValid)? 1:-1;
  };

  function sortByDimension(array)  {
    var noStorage  = [];
    var storage = [];
    array.forEach(item => {
      if (item.dimension) {
        storage.push(item);
      } else {
        noStorage.push(item);
      }
    })
    return [...noStorage , ...storage.sort((a,b) => a.dimension - b.dimension ) ];
  };

  function renderAnimationsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.map((anim, i) => <AnimationRow key={i} anim={anim}/>);
      } else if (search && filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  return (
    <div className="page-container">

      <header className="page-header">
        <h1>Liste des demandes d’animations</h1>
      </header>

      <main className="page-content">

        <div className="row space-between">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <Link to={"/create-animation"} className="btn-edit large">
            Créer une animation
          </Link>
        </div>

        <div className="filters">

          <div
            className="filter"
            style={{ minWidth: '12%'}}
            onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Trier par ordre alphabétique</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translate(-60%, 0)'}}
            onClick={() => setSortBySize(!sortBySize)}>
            <span>Type d'animation</span>
            <img src={filterIcon} className={sortBySize? "rotate180": ""} alt="filter"/>
          </div>

          <div className="filter"
            style={{ transform: 'translate(18%, 0)'}}
            onClick={() => setSortBySize(!sortBySize)}>
            <span>Surface</span>
            <img src={filterIcon} className={sortBySize? "rotate180": ""} alt="filter"/>
          </div>

          <div className="filter" onClick={() => setSortByStatut(!sortByStatut)}>
            <span>Statut</span>
            <img src={filterIcon} className={sortByStatut? "rotate180": ""} alt="filter"/>
          </div>

        </div>

        {context.animations.animationsList.length > 0 ?
          renderAnimationsList()
          :
          <div style={{ height: '70vh'}}>
            <Loader />
          </div>
        }

      </main>

    </div>
  );
};