import React from "react";
import { Link } from "react-router-dom";

import contactGrey  from "../../../../assets/images/icons/contact_grey.svg";
import providerIcon  from "../../../../assets/images/icons/provider_icon.svg";
import contact  from "../../../../assets/images/icons/contact_primary.svg";
import marketBlue  from "../../../../assets/images/icons/market_primary.svg";
import elec  from "../../../../assets/images/icons/elec.svg";
import { useGlobalContext } from "../../../context/GlobalContext";
// import water  from "../../../../assets/images/icons/water.svg";
// import stock  from "../../../../assets/images/icons/stock.svg";

export default function ProviderRow({ provider, dashboard }) {
  const [ context ] = useGlobalContext();
  const { activeExhibition } = context.exhibitions;
  const exhibition = context.exhibitions?.exhibitionsList?.find((ex) => ex._id === activeExhibition); 
  const isSquare = exhibition?.showroomType !== "linear";

  function renderUpdatedLabel() {
    var updatedDate = new Date(provider.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  }

  return (
    <Link to={`/provider-view/${provider._id}/`}>

      <div className="list-row">

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <p>
            {provider.name}
          </p>
        </div>

        {dashboard? 

          <div className="info dashboard">
            <div className="contacts">
              <p>{provider?.users?.length > 0 ?  provider.users.length : 0 }</p> <img src={contact} alt="icon-contact"/>
            </div>
            {provider?.showroom?.dimension > 0 ?
              <div className="stand">
                {provider.showroom.dimension} m {isSquare &&<sup>2</sup>}<img src={marketBlue} alt="icon-contact"/>
              </div>
              :
              <div className="stand not-active">
                <img src={marketBlue} alt="icon-contact"/>
              </div>
            }
          </div>

          :

          <div className="info">
            {provider?.users?.length > 0 ?
              <div className="picto">
                <p>{provider?.users?.filter( u => u?.presence?.length > 0)?.length}</p> <img src={contact} className={'user'} alt="icon-contact"/>
                <p style={{ marginLeft: 10 }}>{provider?.users?.filter( u => u?.presence?.length === 0).length}</p> <img src={contactGrey} className={'user'} alt="icon-contact"/>
              </div>
            :
              <div className="picto not-active">
                <p>{0}</p> <img src={contactGrey} className={'user'} alt="icon-contact"/>
                <p style={{ marginLeft: 10 }}>{0}</p> <img src={contactGrey} className={'user'} alt="icon-contact"/>
              </div>
            }

            {provider?.showroomOption?.power ?
              <div className="picto">
                <span>oui</span> <img src={elec} alt="icon-contact"/>
              </div>
              :
              <div className="picto not-active">
                <span>non</span><img src={elec} alt="icon-contact"/>
              </div>
            }

            {provider?.showroomOption?.table > 0 ?
              <div className="text-center">
                <span>{provider?.showroomOption?.table}</span>
              </div>
              :
              <div className="text-center not-active">
                <span>non</span>
              </div>
            }

            {provider?.showroomOption?.fridge > 0 ?
              <div className="text-center">
                <span>{provider?.showroomOption?.fridge}</span>
              </div>
              :
              <div className="text-center not-active">
                <span>non</span>
              </div>
            }

            {provider?.showroomDimension > 0 ?
              <div className="stand">
                <span>Stand ({provider.showroomDimension} m{isSquare &&<sup>2</sup>})</span><img src={marketBlue} alt="icon-stand"/>
              </div>
              :
              <div className="stand not-active">
                <img src={marketBlue} alt="icon-stand"/>
              </div>

            }
          </div>
        }
        
      </div>

    </Link>
  )
}
