import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { useGlobalContext } from '../../../context/GlobalContext';
import {  translateDate } from "../../../../utils/utils";

export default function Showroom({ showroom, companyId }) {

  const [context] = useGlobalContext();
  const { exhibitionsList, activeExhibition } = context?.exhibitions;
  const exhibitionUnit = exhibitionsList?.find((ex) => ex._id === activeExhibition)?.showroomType;

  const option = showroom?.option;

  return (
    <>

      <div className="title">
        <h2><Translate id="showroom.title"/></h2>
        {showroom?.company &&
          <Link to={`/showroom-edit/${showroom.company}/providers`} className="btn-edit">
            <Translate id="btn.edit"/>
          </Link>
        }
      </div>

      <div className="card info">
        {option ?
          <>
          <div className="information-container">


          <p className="p-col-3">
            <span className="bold"><Translate id="showroom.size"/></span><br/>
            {showroom.dimension} {exhibitionUnit === "square" ? <>m<sup>2</sup></>: <>m</>}
          </p>

          {option.table > 0 &&
            <p className="p-col-3">
              <span className="bold">Tables 1,5m par 0,75m)</span><br/>
              {option.table} tables
            </p>
          }

          {option.chair > 0 &&
            <p className="p-col-3">
              <span className="bold">Chaises</span><br/>
              {option.chair} chaises
            </p>
          }


          {option.fridge > 0 &&
            <p className="p-col-3">
              <span className="bold">Vitrines réfrigérées (0,6m)</span><br/>
              {option.fridge} Vitrines
            </p>
          }

          {option?.fridgeLength > 0 &&
            <p className="p-col-3">
              <span className="bold">Vitrines réfrigérées</span><br/>
              {option?.fridgeLength} m
            </p>
          }   


          {option.power && option.powerQuantity &&
            <p className="p-col-3">
              <span className="bold"><Translate id="showroom.electricity"/></span><br/>
              {option.powerQuantity} Kw
            </p>
          }

          </div>

          {option.standiste &&
          <div className="col-1">
            <span className="bold"><Translate id="showroom.standiste"/></span><br/>
            <div className="information-container">
              <p className="p-col-3 contact border">
                {option.standiste.name}
              </p>
              <p className="p-col-3 contact border">
                <a className="tel" href={`tel:${option.standiste.phone}`}>{option.standiste.phone}</a>
              </p>
              <p className="p-col-3 contact">
                {option.standiste.email}
              </p>
            </div>
          </div>
          }

          <p className="col-1" style={{ fontSize: 12 , opacity: 0.58 }} >Modifié le {translateDate(showroom.updatedAt, 'fr')}</p>

          </>
        
        :
          <div className="container-center">
            <Link to={`/showroom-edit/${companyId}/providers`} className="btn-save" style={{ padding: "5px 15px"}} ><Translate id="btn.createShowroom"/></Link>
          </div>
        }
      </div>

    </>
  );
};
