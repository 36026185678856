import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

export default function Brands({ brands , companyId  }) {

  return (
    <>
      <div className="title">
        <h2><Translate id="company.brands"/></h2>
      </div>
      <div className="row contact-list">
        {brands.map((brand ,i) => {
          console.log(brand)
          return (
            <div key={i} className="col-1">
              <div className="card contact-card brand">
                <h2>{brand.name}</h2>
                <div>
                <Link to={"/provider-view/" + brand._id} className="label">Voir le fournisseur</Link>
                {/* <Link className="label">voir le stand</Link> */}
                </div>
              </div>
            </div>
          )
          })}
      </div>
    </>
  )
}
