import React , { useState , useEffect , useCallback } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { getNotificationsListAction , postNotificationAction } from '../../../actions/notificationsActions';
import { resetUserAction } from '../../../actions/usersActions';
import { translateDate } from '../../../utils/utils';

export default function RecepetionList(props) {

  const { setActivePage } = props;

  const [ context, dispatch ] = useGlobalContext();
  const [ filtredList , setFiltredList ] = useState();
  const [ createIsOpen , setCreateIsOpen ] = useState(false);
  const [ notif , setNotif] = useState({});

  useEffect(() => {
    setActivePage("notifications");
  }, [setActivePage]);

  const getNotificationsList = useCallback(() => {
    setFiltredList();
    resetUserAction(dispatch);
    getNotificationsListAction(dispatch);

  }, [dispatch]);

  useEffect(() => {
    getNotificationsList();
    window.scrollTo(0, 0);
  }, [getNotificationsList]);


  useEffect(() => {
    setFiltredList(context.notifications.notificationsList)
  }, [context.notifications.notificationsList]);

  function renderUpdatedLabel(updatedAt) {
    var updatedDate = new Date(updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  function submitNotif() {
    setCreateIsOpen(!createIsOpen);
    postNotificationAction(dispatch , notif)
  }

  function renderNotificationsList() {
    if (filtredList) {
      return filtredList.sort((a ,b )=> (a.updatedAt < b.updatedAt)? 1:-1).map(notif => {
        return (
          <div className="list-row info notifications" key={notif._id}>
            {renderUpdatedLabel(notif.updatedAt)}
            <h2 className="title">{notif.title}</h2>
            <p className="title">{translateDate(notif.updatedAt, "fr")}</p>
            <p className="text">{notif.text}</p>
          </div>
        )
      })
    }
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des notifications</h1>
      </header>
      <main className="page-content">
        {!createIsOpen ?
          <div className="row flex-end">
            <button
              onClick={() => setCreateIsOpen(!createIsOpen)}
              className="btn-edit large">
              Créer une notification
            </button>
          </div>
          :
          <div className="create-notif">
            <div className="input">
              <input placeholder="titre" onChange={(e) => setNotif({...notif , title: e.target.value } )} />
              <textarea placeholder="texte" onChange={(e) => setNotif({...notif , text: e.target.value } )} />
            </div>

            <div>
              <button
                  onClick={() => submitNotif()}
                  style={{ marginRight: 10 }}
                  className={`btn-edit large success ${notif && !notif.title && "disable"}`}>
                  Valider
              </button>
              <button
                  onClick={() => setCreateIsOpen(!createIsOpen)}
                  className="btn-edit large">
                  Annuler
              </button>
            </div>
          </div>
        }

        {renderNotificationsList()}

      </main>

    </div>
  )
}