import React from "react";
import { LocalizeProvider } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { GlobalProvider } from './components/context/GlobalContext'

import usersReducer from './reducers/usersReducer';
import authReducer from './reducers/authReducer';
import providersReducers from './reducers/providersReducer';
import shopsReducer from './reducers/shopsReducer';
import animationReducer from './reducers/animationsReducer';
import assemblyReducer from './reducers/assemblyReducer';
import offersReducer from './reducers/offersReducer';
import receptionReducer from './reducers/receptionReducer';
import ordersReducer from './reducers/ordersReducer';
import notificationsReducer from './reducers/notificationsReducer';
import exhibitionsReducer from "./reducers/exhibitionsReducer";

import globalTranslations from "./assets/translations/global.json";
import MainRouter from "./components/MainRouter";

import './styles/App.scss';

export default function App() {

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    users: usersReducer,
    exhibitions: exhibitionsReducer,
    auth: authReducer,
    providers: providersReducers,
    shops: shopsReducer,
    animations: animationReducer,
    assemblies: assemblyReducer,
    reception: receptionReducer,
    offers: offersReducer,
    orders: ordersReducer ,
    notifications: notificationsReducer
  });
  
  const initialState = reducers( {} , { type: '__INIT__' });
  
  return (
    <LocalizeProvider
      initialize={{
      languages: [
        { name: "French", code: "fr" },
        { name: "English", code: "en" }
      ],
      translation: globalTranslations,
      options: { renderToStaticMarkup }
      }}>
      <GlobalProvider
        reducers={reducers}
        initialState={initialState}
        >
        <MainRouter />
      </GlobalProvider>
    </LocalizeProvider>
  );
}

