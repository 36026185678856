import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from '../../context/GlobalContext';
import { getBadgeFileAction } from '../../../actions/receptionActions';
import { uptadeUserPresenceAction } from '../../../actions/usersActions';

import contact  from "../../../assets/images/icons/contact.svg";
import download  from "../../../assets/images/icons/download-badge.svg";
import flag  from "../../../assets/images/icons/flag.svg";

export default function ReceptionRow(props) {
  const { user , event , eventLabel } = props;
  const dispatch = useGlobalContext()[1];

  return (
    <div className="list-row">
      <div className="name">
          <div className="icon">
            <img src={contact} alt="user"/>
          </div>
          {user.profile.lastName} {user.profile.firstName}
        </div>
        <div className="info reception">

          <div className="company">
            {user && user.company && user.company[0] &&
              <p>{user.company[0].name}</p>
            }
          </div>
          

            {user && user?.isHere?.find(item => item === event) ?
              <div
                className="btn-is-here"
                onClick={() => uptadeUserPresenceAction(dispatch , user._id, event )}>
                <div className="picto">
                  <img src={flag} alt="icon-flag"/>
                  <p style={{ width: 30 , marginLeft: 10 }}>oui</p>
                </div>
              </div>
              :
              <div
                className="btn-is-here"
                onClick={() => uptadeUserPresenceAction(dispatch , user._id, event )}>
                <div className="picto not-active">
                  <img src={flag} alt="icon-flag"/>
                  <p style={{ width: 30 , marginLeft: 10 }}>non</p>
                </div>
              </div>
            }

          <div className="badges-buttons">
            
            <Link to={`/badge/${user._id}/${event}` }
              className="btn-edit"
              style={{ padding: 10 , marginRight: 10 }}>
              Afficher le badge
            </Link>
            <button
              className="btn-edit circle"
              onClick={() =>  getBadgeFileAction(dispatch , user , eventLabel )}>
              <img src={download} alt="download"/>
            </button>
          </div> 
        </div>
    </div>
  )
}
