import React , { useEffect , useState , useCallback } from 'react';
import { Translate } from "react-localize-redux";
import { useHistory , Link } from 'react-router-dom';
import Select from 'react-select';
import selectStyle from '../../Partials/selectStyle';
import { useGlobalContext } from '../../context/GlobalContext';
import { getEditListsAction } from '../../../actions/providersActions';
import { getShopAction , updateShopAction }from '../../../actions/shopsActions';
import { store } from 'react-notifications-component';

import '../../../styles/Edit.scss';

import Loader from "../../Partials/Loader";
import Input from "../../Partials/form_fields/Input";
import SelectList from '../../Partials/SelectList';

import shopsIcon  from "../../../assets/images/icons/shops_icon.svg";
import SelectActivities from '../../Partials/SelectActivities/SelectActivities';

export default function ShopEdit(props) {

  props.setActivePage("shops");
  
  const history = useHistory();
  const [ context , dispatch ] = useGlobalContext();

  const [ name , setName ] = useState();
  const [ acdlec , setAcdlec ] = useState();
  const [ centrale , setCentrale ] = useState();
  const [ city , setCity ] = useState();
  const [ mainUser , setMainUser ] = useState(null);
  const [ activities , setActivities] = useState();

  var shop = context.shops.shop;
  let usersOptions = shop?.users?.map(u => ({ value: u._id , label: `${u.profile.firstName} ${u.profile.lastName}` }))


  const getShop = useCallback(() => {
    getShopAction( dispatch, props.match.params.id);
  }, [dispatch , props.match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if(!shop.company) {
      getShop();
      window.scrollTo(0, 0);
    }
  }, [getShop, shop])

  useEffect(() => {
    if (shop.company) {
      getEditLists();
      setName(shop.company.name);
      setAcdlec(shop.company.acdlec)
      setActivities(shop.company.domains);
      setCentrale(shop.company.centrale);
      setMainUser(shop.company.mainUser)
      setCity(shop.company.location.city);
    } else {
      getShop();
      window.scrollTo(0, 0);
    }
  }, [shop , history , getShop , getEditLists]);

  useEffect(() => {
    if (context.shops.message === "Company successfully updated!") {
      successUpdate();
      dispatch({
        type : 'MESSAGE_SHOPS',
        payload : {}
      });
    }
  },[context.shops.message , dispatch])

  function updateShop() {
    const shopUpdated = {
      id: shop.company._id,
      name: name,
      acdlec: acdlec,
      centrale: centrale,
      domains : activities,
      mainUser: mainUser,
      location: {
        city: city
      }
    }
    updateShopAction(dispatch, shopUpdated);
  }

  function successUpdate() {
    store.addNotification({
      message: "Entreprise mise à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  function renderShopEdit() {
    var lists = [];
    if (context.providers.lists) {
      lists = context.providers.lists
    };
    return (
      <>
        <header className="page-header">
          <div className="icon">
            <img src={shopsIcon} alt="icon-shop"/>
          </div>
          <h1>{name}</h1>
        </header>
        <main className="page-content">
          <Link to={"/shop-view/" + shop.company._id } className="btn-edit mb-10">
          <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
          </Link>
          <div className="card edit-container">

            <div className="edit-row row">

              <div className="col-3">
                <Input
                  label={<Translate id="company.social"/>}
                  value={name}
                  type={"text"}
                  className="input"
                  onChange={(value) => setName(value.toUpperCase())}
                  />
              </div>

              <div className="col-3">
                <Input
                  label={<Translate id="company.acdlec"/>}
                  value={acdlec}
                  type={"text"}
                  className="input"
                  onChange={(value) => setAcdlec(value)}
                  />
              </div>

              <div className="col-3">
                <Input
                  label={<Translate id="company.central"/>}
                  value={centrale}
                  type={"text"}
                  className="input"
                  onChange={(value) => setCentrale(value)}
                  />
              </div>     

              <div className="col-3">
                <Input
                  label={<Translate id="company.city"/>}
                  value={city}
                  type={"text"}
                  className="input"
                  onChange={(value) => setCity(value.toUpperCase())}
                  />
              </div>

              <div className="col-3">
                <label className="bold" style={{ marginBottom: 4 , display: 'block' }}><Translate id="company.mainUser"/></label>
                <Select
                  options={usersOptions}
                  value={usersOptions?.find(u => u.value === mainUser) ? usersOptions?.find(u => u.value === mainUser) : null }
                  onChange={(val) => setMainUser(val.value)}
                  isMulti={false}
                  styles={selectStyle}
                  placeholder={<Translate id="placeholder.select"/>}
                  />
              </div>   

            </div>

              <SelectActivities
                label={<Translate id="company.activities"/>}
                list={lists && lists.activities ? lists.activities : []}
                activeList={activities ? activities : []}
                onChange={(array) => setActivities(array)}
              />

          </div>
          <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
            <button
              className="btn-save"
              onClick={() => updateShop()}
              >
                <Translate id="btn.save"/>
            </button>
          </div>
        </main>
      </>
    )
  }

  return (
    <div className="page-container">
      {shop && shop.company ? 
        renderShopEdit()
        :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  )
}
