import React , { useEffect , useCallback } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

import { useGlobalContext } from '../../context/GlobalContext';
import { getShopAction } from '../../../actions/shopsActions';
import { getEditListsAction } from '../../../actions/providersActions';

import Company from '../../Partials/Company';
import Contacts from '../../Partials/Contacts';

import shopIcon  from "../../../assets/images/icons/shops_icon.svg";

export default function ShopView({ setActivePage, match }) {
  const [ context , dispatch ] = useGlobalContext();

  const shop = context.shops.shop;
  const daysOptions = context.providers?.lists?.days;

  const getShop = useCallback(() => {
    getShopAction( dispatch, match.params.id);
  }, [dispatch , match.params.id]);

  const getEditLists = useCallback(() => {
    getEditListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getShop();
    setActivePage("shops");
    getEditLists();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [getShop , getEditLists]);

  return (
    <div className="page-container">
      {shop?.company &&
        <>
          <header className="page-header">
            <div className="icon">
              <img src={shopIcon} alt="icon-Shop"/>
            </div>
            <h1>{shop.company.name}</h1>
          </header>
          <main className="page-content">
            <Link to={"/shops"} className="btn-edit mb-10">
              <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
            </Link>

            <div className="row col-2">
              <div className="col-2">
                <Company
                  company={shop.company}
                  dispatch={dispatch}
                  contacts={shop.users}
                  root="shop" />
              </div>
              <div className="col-2">
                <Contacts
                  contacts={shop.users}
                  daysOptions={daysOptions}
                  companyId={shop.company._id}
                  root="shop" />
              </div>
            </div>
          </main>
        </>
      }
    </div>
  )
}