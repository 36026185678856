import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel(props) {
  console.log(props)
  const renderExcelToExport = () => {
    if (props.dataToExport) {
      return props.dataToExport.map((sheet ,i) => {
        return (
          <ExcelSheet
            key={i}
            data={sheet.sheetData}
            name={sheet.sheetName}>
            {sheet.sheetHeader.map(( colValue ,i ) => (<ExcelColumn key={i} label={colValue} value={colValue} /> ))}
          </ExcelSheet>
        );
      });
    };
  };

  return (
    <ExcelFile
      element={
        <button
          onClick={() => 
            props.dispatch({
              type : 'EXPORT_PROVIDERS_LIST',
              payload : null
            })
          }
          className="btn-edit success"
          style={{ width: 100 , height:36 , marginTop: 5 }}>
          Télécharger
        </button>
      }
      filename={props.fileName}
      >
      {renderExcelToExport()}
    </ExcelFile>
  );
}
