import React from 'react';
import { API_URL } from '../../../../actions';
// import { API_URL } from '../../../../actions';
import { deleteFileAction, postFilesAction } from '../../../../actions/filesActions';
import cloud from '../../../../assets/images/icons/cloud.svg';
import { useGlobalContext } from '../../../context/GlobalContext';
import styles from './InputFile.module.scss';

export default function InputFile({ file, id, onChange , label, isMulti = false }) {
  const dispatch = useGlobalContext()[1];

  async function handleChangeFile(files) {
    console.log(files)
    if (isMulti) return;
    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', file.name);
    formData.append('description', file.name);
    formData.append('alt', file.name);
    const res = await postFilesAction(dispatch, formData);

    if (!isMulti && res.length > 0) {
      onChange(res[0]._id);
    }

  }

  async function handleDelete() {
    const deleted = await deleteFileAction(dispatch, file._id);
    if (deleted) {
      onChange(null);
    }
  }

  return (
    <div className={`${styles.container} ${file ? '': styles['is-input']}`}>
      <input
        id={id}
        className={styles.input}
        type="file"
        onChange={(e) => handleChangeFile(e?.target?.files)}
      />
      {file?.extension && file?.path && <div className={styles.media}>
        {(file.extension === "jpeg" || file.extension === "png") && <img
          src={`${API_URL}/files/${file.path.replace('upload/', '')}`}
          alt={file.name} /> 
        }
        {file.extension === "mp4" && file?.path && <video width="100%" height="auto" controls>
            <source
              src={`${API_URL}/files/${file.path.replace('upload/', '')}`}
              type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        }

        <button
          className={styles.delete}
          onClick={() => handleDelete()}
        >
          Supprimer le fichier
        </button>
      </div>}
      {!file && <label
        htmlFor={id}
        className={styles.label}
        >
        <div className={styles.icon}>
          <img src={cloud} alt="upload" />
        </div>
        <p>{label}</p>
      </label>}

    </div>
  )
}
