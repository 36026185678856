import React from 'react';
import styles from './Modale.module.scss';

export default function Modale({ children , isOpen }) {
  return (
    <div className={`${styles.container} ${isOpen ? styles.active : ""}`}>
      {children}
    </div>
  )
}
