import React , { useEffect , useCallback , useState } from 'react';
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { store } from 'react-notifications-component';

// import { Translate } from "react-localize-redux";

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import ProviderRow from './Providers_Partials/ProviderRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import { useGlobalContext } from '../../context/GlobalContext';
import { triAlpha } from '../../../utils/utils';
import { getProvidersListAction , exportProvidersListAction , uploadCompaniesAction } from '../../../actions/providersActions';

import '../../../styles/List.scss';
import { Link } from 'react-router-dom';
import { EXPORT_PROVIDERS_LIST, EXPORT_PROVIDERS_MAIL, MESSAGE_PROVIDER, MESSAGE_USERS, REMOVE_PROVIDER_VIEW, SCROLL_ON_PROVIDERS, SCROLL_ON_SHOPS, SCROLL_ON_STANDS } from '../../../actions/types';
import Modale from '../../Partials/Modale/Modale';
import UploadCompaniesModal from '../../Partials/UploadCompaniesModal/UploadCompaniesModal';


export default function ProvidersList({ setActivePage, activePage }) {

  const [ context, dispatch ] = useGlobalContext();

  const {
    scrollProviders,
    providersList,
    exportProvidersList,
    message,
  } = context.providers;

  const { exhibitionsList, activeExhibition } = context?.exhibitions;
  const exhibitionUnit = exhibitionsList?.find((ex) => ex._id === activeExhibition)?.showroomType;

  const [ search , setSearch ] = useState("");
  const [ filtredList , setFiltredList ] = useState();
  const [ sortBySize , setSortBySize ]= useState(false);
  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByElec , setSortByElec ] = useState(false);
  const [ sortByTable , setSortByTable ] = useState(false);
  const [ sortByFridge , setSortByFridge ] = useState(false);
  const [ sortByContacts , setSortByContacts ] = useState(false);
  const [ updateProviders, setUpdateProviders] = useState(false);

  const getProvidersList = useCallback(() => {
    getProvidersListAction(dispatch);
    dispatch({
      type : EXPORT_PROVIDERS_LIST,
      payload : null
    });

    dispatch({
      type : EXPORT_PROVIDERS_MAIL,
      payload : null
    });

    dispatch({
      type : MESSAGE_USERS,
      payload : ""
    });

    dispatch({
      type : REMOVE_PROVIDER_VIEW,
      payload : {}
    });

    dispatch({
      type : SCROLL_ON_STANDS,
      payload : 0
    });

    dispatch({
      type : SCROLL_ON_SHOPS,
      payload : 0
    });

  }, [dispatch]);

  useEffect(() => {
      getProvidersList();
      setActivePage("providers");
  }, [getProvidersList, setActivePage]);

  useEffect(() => {
    window.scrollTo(0, scrollProviders)
  });

  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : SCROLL_ON_PROVIDERS,
      payload : -currPos.y
    });
  });

  useEffect(() => {
    if (providersList?.length >= 0) {
    let providers = providersList
      providers.sort(function(a, b) {
        var dateA = new Date(a.updatedAt)
        var dateB = new Date(b.updatedAt)
        return dateA.getTime() - dateB.getTime()
      }).reverse();
      setFiltredList(providers);
    }
  }, [providersList])

  useEffect(() => {
    if (providersList?.length > 0) {
      var newList = providersList.filter(provider => {
        if (provider?.name?.toLowerCase()?.includes(search?.toLowerCase())){
          return provider;
        } else if (provider.users && provider.users.length > 0 && provider.users.find(user => user?.email?.toLowerCase()?.includes(search?.toLowerCase()))) {
          return provider;
        } else if (provider.users && provider.users.length > 0 && provider.users.find(user => user?.profile?.lastName?.toLowerCase()?.includes(search?.toLowerCase()))) {
          return provider;
        }
        return null;
      });
      setFiltredList(newList);
    }
  }, [search , providersList ]);

  useEffect(() => {
    if (filtredList?.length > 0) {
      let newList = _.cloneDeep(filtredList);
      if (sortBySize) {
        setFiltredList(newList.sort(function(a, b) { return a.showroomDimension - b.showroomDimension }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.showroomDimension - b.showroomDimension }));
      } 
    }
    // eslint-disable-next-line
  }, [sortBySize]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByContacts) {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }).reverse());
      } else {
        setFiltredList(newList.sort(function(a, b) { return a.users.length - b.users.length }));
      } 
    }
    // eslint-disable-next-line
  }, [sortByContacts]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByFridge) {
        setFiltredList(sortByQuantityKey(newList , "fridge").reverse());
      } else {
        setFiltredList(sortByQuantityKey(newList, "fridge"));
      } 
    }
    // eslint-disable-next-line
  }, [sortByFridge]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByElec) {
        setFiltredList(sortArrayByBooleen(newList , "power"));
      } else {
        setFiltredList(sortArrayByBooleen(newList , "power").reverse());
      } 
    }
    // eslint-disable-next-line
  }, [sortByElec]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByTable) {
        setFiltredList(sortByQuantityKey(newList , "table").reverse());
      } else {
        setFiltredList(sortByQuantityKey(newList, "table"));
      } 
    }
    // eslint-disable-next-line
  }, [sortByTable]);

  useEffect(() => {
    if (filtredList) {
      let newList = _.cloneDeep(filtredList);
      if (sortByAlpha) {
        setFiltredList(newList.sort(triAlpha));
      } else {
        setFiltredList(newList.sort(triAlpha).reverse());
      }
    }
    // eslint-disable-next-line
  }, [sortByAlpha]);

  useEffect(() => {
    if (message) {
      successNotif(message);
      getProvidersList();
    }
  });

  function successNotif(message) {
    store.addNotification({
      message,
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : MESSAGE_PROVIDER,
      payload : null,
    });
  }

  function sortArrayByBooleen( array , key)  {
    var newArray = []
    array.forEach(item => {
      if (item.showroomOption && item.showroomOption[key]) {
        newArray.unshift(item)
      } else {
        newArray.push(item)
      }
    })
    return newArray;
  };
  

  function sortByQuantityKey(array , key)  {
    var noKey  = []
    var keys = []
    array.forEach(item => {
      if (item.showroomOption && item.showroomOption[key]) {
        keys.push(item)
      } else {
        noKey.push(item)
      }
    })
    return [...noKey , ...keys.sort((a,b) => a.showroomOption[key] - b.showroomOption[key]) ];
  };

  function renderExportProviders() {
    if (exportProvidersList?.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={exportProvidersList.fileName}
        dataToExport={exportProvidersList.dataToExport}/>
    } else {
        return <button
          onClick={() => exportProvidersListAction(dispatch , { company: null })}
          className="btn-edit hide-tablet"
          style={{ width: 100 , height:36 , marginTop: 5 , margin: 10 }}>
          Exporter
        </button>
    }
  }

  function renderProviderList() {
    if (filtredList?.length > 0) {
      return filtredList.map((provider, i) => <ProviderRow key={i} provider={provider} activePage={activePage} exhibitionUnit={exhibitionUnit} dashBoard={false}/>);
    } else if (search && filtredList.length === 0) {
      return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
    }
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des fournisseurs</h1>
      </header>
      <main className="page-content">
        <div className="row export">
          <div className="col-3 input">
            <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
            <img className="search-icon" src={searchIcon} alt="search"/>
          </div>
          <div>
            <Link to={'/provider-create/'}>
            <button 
              className="btn-edit"
              style={{ height:36 , marginTop: 5 , margin: 10 , paddingLeft: 15 , paddingRight: 15 }}>
              Ajouter un fournisseur
            </button>
            </Link>
            <button 
              className="btn-edit hide-tablet"
              style={{ height:36 , marginTop: 5 , margin: 10 , paddingLeft: 15 , paddingRight: 15 }}
              onClick={() => setUpdateProviders(true)} 
            >
              Importer des fournisseurs
            </button>
            {/* {renderExportMail()} */}
            {renderExportProviders()}
          </div>
        </div>
        <div className="filters">
          <div className="filter" onClick={() => setSortByAlpha(!sortByAlpha)}>
            <span>Trier par ordre alphabétique</span>
            <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
          </div>
          <div style={{ minWidth: '70%', display: 'flex', justifyContent: 'space-between'}}>
            <div className="filter" onClick={() => setSortByContacts(!sortByContacts)}>
              <span>Nombre d’invités </span>
              <img src={filterIcon} className={sortByElec? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" onClick={() => setSortByElec(!sortByElec)}>
              <span>Electricité </span>
              <img src={filterIcon} className={sortByElec? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" onClick={() => setSortByTable(!sortByTable)}>
              <span>Tables</span>
              <img src={filterIcon} className={sortByTable? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" onClick={() => setSortByFridge(!sortByFridge)}>
              <span>Frigos</span>
              <img src={filterIcon} className={sortByFridge? "rotate180": ""} alt="filter"/>
            </div>
            <div className="filter" style={{ minWidth: '26%', display: 'flex', justifyContent: 'flex-end'}} onClick={() => setSortBySize(!sortBySize)}>
              <span>Trier par dimensions</span>
              <img src={filterIcon} className={sortBySize? "rotate180": ""} alt="filter"/>
            </div>
          </div>
        </div>
        {providersList ? 
          renderProviderList()
        :
          <div style={{ height: '70vh'}}>
            <Loader />
          </div>
        }
      </main>
      <Modale isOpen={updateProviders}>
        {updateProviders && <UploadCompaniesModal
          type="fournisseur"
          label="Mettre à jour les fournisseurs"
          onSubmit={(data) => uploadCompaniesAction(dispatch, data)}
          close={() => setUpdateProviders(false)}
        />}
      </Modale>
    </div>
  )
}