import {
  GET_RECEPTION_LIST,
  UPDATE_USER_PRESENCE,
  ERROR_RECEPTION,
  MESSAGE_RECEPTION,
  GET_BADGE
} from "../actions/types"

const initialState = {
  receptionList: [],
  error: {},
  message: "",
  badge: null
};

export default function receptionReducer(state = initialState, action) {
  const list = state.receptionList ? [...state.receptionList] : [];
  const index = list?.findIndex((ex) => ex?._id === action.payload?._id);
  switch (action.type) {
    case UPDATE_USER_PRESENCE:
      if (index !== -1) {
        list[index] = {
          ...action.payload,
          company: list[index].company
        };
      }
      return {...state, receptionUser: action.payload, receptionList: list }
    case GET_RECEPTION_LIST:
      return {...state, receptionList : action.payload}
    case MESSAGE_RECEPTION:
      return {...state, message : action.payload}
    case ERROR_RECEPTION:
      return {...state, error : action.payload}
    case GET_BADGE:
      return {...state, badge : action.payload}
    default:
      return {...state};
  }
}
