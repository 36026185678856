import {
  GET_PROVIDERS_LIST,
  ERROR_PROVIDERS,
  GET_PROVIDER,
  REMOVE_PROVIDER_VIEW,
  GET_EDIT_LISTS,
  MESSAGE_PROVIDER,
  SCROLL_ON_PROVIDERS,
  SCROLL_ON_STANDS,
  EXPORT_PROVIDERS_LIST,
  RESET_PROVIDER,
  EXPORT_PROVIDERS_MAIL,
} from "../actions/types"

const initialState = {
  providersList: null,
  lists : {},
  provider: {},
  error: {},
  message: null,
  scrollProviders: 0,
  scrollStands: 0,
  exportProvidersList: null,
  exportProvidersMails : null
};

export default function providersReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PROVIDER:
      return {...state,
        provider : action.payload.provider, 
        message : action.payload.message
      }
    case GET_PROVIDERS_LIST:
      return {...state, providersList : action.payload}
    case GET_PROVIDER:
      return {...state, provider : action.payload}
    case REMOVE_PROVIDER_VIEW:
      return {...state, provider : action.payload}
    case ERROR_PROVIDERS:
        return {...state, error: action.payload}
    case GET_EDIT_LISTS:
      return {...state, lists: action.payload}
    case MESSAGE_PROVIDER:
      return {...state, message: action.payload}
    case SCROLL_ON_PROVIDERS:
      return {...state, scrollProviders: action.payload}
    case SCROLL_ON_STANDS:
      return {...state, scrollStands: action.payload}
    case EXPORT_PROVIDERS_MAIL:
      return {...state, exportProvidersMails: action.payload}
    case EXPORT_PROVIDERS_LIST:
      return {...state, exportProvidersList: action.payload}
    default:
      return {...state};
  }
}
