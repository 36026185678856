import React from 'react';

import { format } from 'date-fns';
import Input from '../../../../Partials/form_fields/Input';
import styles from './Form.module.scss';
import HtmlEditor from '../../../../Partials/form_fields/HtmlEditor/HtmlEditor';
import InputFile from '../../../../Partials/form_fields/InputFile/InputFile';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { putExhibitionAction } from '../../../../../actions/exhibitionsActions';
import StyledSelect from '../../../../Partials/form_fields/StyledSelect/StyledSelect';
import { dimensionsOptions } from '../../../../../constants/constants';
import SwitchField from '../../../../Partials/form_fields/SwitchField';


export default function DescriptionForm({ form, handleChangeForm , width }) {
  const [ context , dispatch ] = useGlobalContext();
  const { exhibition }  = context.exhibitions;
  return (
    <div className={styles.container} style={{ width }}>
      <div className={styles['col-2']}>
        <div className={styles.block}>
          <h2>Info</h2>
          <div className={styles.row}>
            <div className={styles['col-1']}>
            <Input
              label="Non du salon"
              value={form?.name || ''}
              placeholder={""}
              type={"text"}
              className="input secondary"
              required={false}
              loading={false}
              onChange={(val) => handleChangeForm({ name : val})}
            />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Lieu"
                value={form?.location || ''}
                placeholder={""}
                type={"text"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ location : val })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Adresse"
                value={form?.address || ''}
                placeholder={""}
                type={"text"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ address : val })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-2']}>
              <Input
                label="Date du début"
                value={form?.startDate ? format(new Date(form?.startDate), 'yyyy-MM-dd') : ''}
                placeholder={"dd-mm-yyyy"}
                type={"date"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ startDate : new Date(val) })}
              />
            </div>
            <div className={styles['col-2']}>
              <Input
                label="Date de fin"
                value={form?.endDate ? format(new Date(form.endDate), 'yyyy-MM-dd') : ''}
                placeholder={"dd-mm-yyyy"}
                type={"date"}
                min={form?.startDate ? format(new Date(form?.startDate), 'yyyy-MM-dd') : ''}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ endDate : new Date(val) })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-2']}>
              <StyledSelect
                className="input secondary"
                label="Unité de dimension"
                value={dimensionsOptions?.find((d) => d.value === form?.showroomType)}
                options={dimensionsOptions}
                onChange={(opt) => handleChangeForm({ showroomType : opt.value })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form.displayShowroom}
                  handleChange={(value) => handleChangeForm({
                    displayShowroom : value,
                  })}
                >
                  <p>
                    Réservation de stand 
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form.displayShowroomSize}
                  handleChange={(value) => handleChangeForm({ displayShowroomSize : value })}
                >
                  <p>
                    Renseigner la taille du stand
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form.fridgeAvailable}
                  handleChange={(value) => handleChangeForm({ fridgeAvailable : value })}
                >
                  <p>
                    Vitrine réfrigérée
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          {form.fridgeAvailable &&
            <div className={styles.row}>
              <div className={styles['col-1']}>
                <div className="container-switch">
                  <SwitchField
                    checked={form.fridgeProvided}
                    handleChange={(value) => handleChangeForm({ fridgeProvided : value })}
                  >
                    <p>
                      Vitrine réfrigérée fournies
                    </p>
                  </SwitchField>
                </div>
              </div>
            </div>
          }
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form.powerAvailable}
                  handleChange={(value) => handleChangeForm({ powerAvailable : value })}
                >
                  <p>
                    Prise éléctrique
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <div className="container-switch">
                <SwitchField
                  checked={form.chairsAvailable}
                  handleChange={(value) => handleChangeForm({ chairsAvailable : value })}
                >
                  <p>
                    Chaise fournies
                  </p>
                </SwitchField>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <h2>Contacts</h2>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Nom du contact"
                value={form?.contact?.name || ''}
                placeholder={""}
                type={"text"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ contact : { ...form.contact , name: val }})}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Téléphone"
                value={form?.contact?.tel || ''}
                placeholder={""}
                type={"tel"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ contact : { ...form.contact , tel: val }})}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['col-1']}>
              <Input
                label="Email"
                value={form?.contact?.email || ''}
                placeholder={""}
                type={"email"}
                className="input secondary"
                required={false}
                loading={false}
                onChange={(val) => handleChangeForm({ contact : { ...form.contact , email: val }})}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['col-2']}>
        <div className={styles.block}>
          <h2>Description</h2>
           <HtmlEditor
            defaultValue={exhibition?.description}
            onChange={(val) => handleChangeForm({ description : val})}
           />
        </div>
        {form?._id &&
          <div className={styles.block}>
            <h2>Image de présentation</h2>
            <InputFile
              file={form?.cover || ''}
              id="cover"
              label="Télécharger votre image de présentation"
              onChange={(val) => putExhibitionAction(dispatch, exhibition?._id, { cover: val })}
            />
          </div>
        }
      </div>
    </div>
  )
}
