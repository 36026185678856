import React, { useEffect , useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import { useParams , useHistory } from  'react-router-dom';

import { useGlobalContext } from '../../context/GlobalContext';
import { getBadgeAction } from '../../../actions/receptionActions';
import { getUserAction } from '../../../actions/usersActions';
import { uptadeUserPresenceAction } from '../../../actions/usersActions';

import logo from "../../../assets/images/logo-leclerc.svg"

import Loader from "../../Partials/Loader";

export default function Badge(props) {
  const { setActivePage } = props;
  const param = useParams();
  let history = useHistory();

  
  const [ context, dispatch ] = useGlobalContext();
  const daysOptions = context.exhibitions.daysOptions;
  const dayLabel = daysOptions?.find((d) => d.value === param.event)?.label;

  const [ badge , setBadge ] = useState()

  useEffect(() => {
    setActivePage("reception");
  }, [param , setActivePage]);

  const getBadge = useCallback(() => {
      getBadgeAction(dispatch , param.id);
      getUserAction(dispatch , param.id);
    }, [dispatch , param.id ],)

  useEffect(() => {
    getBadge();
    setBadge();
    window.scrollTo(0, 0);
  }, [getBadge])

  useEffect(() => {
    if (context.reception.badge) {
      setBadge(context.reception.badge)
    }
  }, [context.reception.badge]);

  function validUserPresence() {
    uptadeUserPresenceAction(dispatch , context.users.user._id, param.event);
  }

  useEffect(() => {

    if (context.users.user && context.users.user.isHere && context.users.user.isHere.find(item => item === param.event)) {
      history.push(`/scan/${param.event}`)
    }

  }, [context.users.user, history, param.event]);

  function renderBadge() {
    if(badge && context.users.user && context.users.user.profile ) {
      var profile = context.users.user.profile;
      var user = context.users.user     
      return (
        <div className="badge-container">
          <p style={{ marginTop: 20 }} >{dayLabel}</p>
          <h1 style={{ marginTop: 10 }} className="bold">{profile.firstName} {profile.lastName}</h1>
          <p style={{ margin: 10 }} >{profile.function}</p>
          {user && user.company && user.company[0] &&
            <p className="bold" >{user.company[0].name}</p>
          }
          <img style={{ margin: 10 }} src={badge} alt="qr-code"/>

          <button
            onClick={() => validUserPresence()}
            className="btn-edit"
            style={{ padding: 10 , margin: 30 }}>Valider le visiteur</button>

          <div className="logo-container bold">
            <img src={logo} alt="logo-btlec"/>
            <p>BTlec Ouest</p>
          </div>

        </div>
      )
    } else {
      return (
        <div style={{ height: '70vh'}}>
          <Loader />
        </div>
      )
    }
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Badge {dayLabel}</h1>
      </header>
      <main className="page-content">
        <Link
            to={"/reception/" + param.event}
          >
          <button className="btn-edit" style={{ paddingLeft: 10 , paddingRight: 10 }}>retour à la liste</button>
        </Link>
        {renderBadge()}
      </main>
    </div>
  )
}
