import React , { useEffect , useState } from 'react';
import QRcodeReader from './QRcodeReader';
import { useParams ,  Link } from  'react-router-dom';

import '../../../styles/scan.scss';
import { useGlobalContext } from '../../context/GlobalContext';

export default function Scan(props) {
  const param = useParams();
  const { setActivePage } = props;

  const context = useGlobalContext()[0];
  const daysOptions = context.exhibitions.daysOptions;
  const dayLabel = daysOptions?.find((d) => d.value === param.event)?.label;

  const [ badgeValid, setIsBadgeValid ] = useState(false);

  useEffect(() => {
    setActivePage("reception-" + param.event);

  }, [param , setActivePage])
  
  return (
    <div className="page-container">
      <header className="page-header">
        {badgeValid?
          <h1>Badge <span className="text-primary">{dayLabel}</span></h1>
          :
          <h1>Scanner <span className="text-primary">{dayLabel}</span></h1>
        }
      </header>
      <main className="page-content">
        <Link
          to={"/reception/" + param.event}
        >
          <button className="btn-edit" style={{ paddingLeft: 10 , paddingRight: 10 }}>retour à la liste</button>
        </Link>
        <QRcodeReader
          setIsBadgeValid={setIsBadgeValid}
          event={param.event} />
      </main>
    </div>
  );
}