import React, { useEffect , useState , useCallback } from 'react';
import { Translate } from "react-localize-redux";

import { useGlobalContext } from '../../context/GlobalContext';
import { getDashbordAction } from '../../../actions/authActions';

import ProviderRow from '../Providers/Providers_Partials/ProviderRow';
import ShopRow from '../../Pages/Shops/Shops_Partials/ShopsRow';

import '../../../styles/Dashboard.scss'
import Loader from '../../Partials/Loader';

export default function Home({ setActivePage }) {

  const [ context , dispatch ] = useGlobalContext();
  const { activeExhibition } = context.exhibitions;
  const exhibition = context.exhibitions?.exhibitionsList?.find((ex) => ex._id === activeExhibition); 
  const isSquare = exhibition?.showroomType !== "linear";
  const [ providers , setProviders ] = useState([]);
  const [ shops , setShops ] = useState([]);

  const getDashbord = useCallback(() => {
    getDashbordAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getDashbord();
    setActivePage("home")
    window.scrollTo(0, 0);
  },[getDashbord, setActivePage, activeExhibition]);

  useEffect(() => {
    if (context.auth.dashboard && context.auth.dashboard.fournisseurs ) {
      setProviders(context.auth.dashboard.fournisseurs);
      setShops(context.auth.dashboard.magasins);
    }
  }, [context.auth.dashboard]);

  function renderDashbord() {

    return (
      <>
        <section className="dashboard-row" style={{ marginBottom: 20 }}>
          {/* BLUE CARD */}
          <div className="col-2-3">

            <div className="dashboard-card providers bold">

              <div className="dashboard-row">

                <div className="col-2-3 main-infos">

                  <div className="number-container">
                    <h2>fournisseurs INSCRITS</h2>
                    <h1 className="number-xl">{providers.nbFournisseursWithStand} / {providers.nbFournisseurs}</h1>
                  </div>

                  <div className="number-container">
                    <h2>Nombre de contacts</h2>
                    <h1 className="number">{providers.nbContactsFournisseurs} </h1>
                  </div>
                
                </div>
                <div className="col-1-3 prestas">
                  <div className="number-container">
                    <h2>stand réservés</h2>
                    <span className="number">
                      {providers.surfaceStand} <span className="regular">m{isSquare &&<sup>2</sup>}</span></span>
                  </div>
                  <div className="number-container">
                    <h2>Nombre de déjeuners</h2>
                    <h1 className="number">{providers.nbLunch} </h1>
                  </div>
                </div>

              </div>

            </div>

          </div>

          <div className="col-1-3">

            <div className="dashboard-card shops bold">

              <div className="col-1">
                <div style={{padding: 20 }}>
                  <h2>Magasins INSCRITS</h2>
                  <h1 className="number-xl">{shops.nbMagasinsPresent} / {shops.nbMagasins}</h1>
                  <h2 style={{ marginTop: 10 }}>Nombre de contacts</h2>
                  <h1 className="number">{shops.nbContactsMagasins}</h1>
                  <h2 style={{ marginTop: 10 }}>Nombre de déjeuners</h2>
                  <h1 className="number">{shops.nbLunch}</h1>
                </div>
              </div>

            </div>
          </div>

        </section>

        <section className="row col-2">

          <div className="col-2">
            <div className="title">
              <h2>Derniers fournisseurs inscrits</h2>
            </div>
            {providers.fournisseurs.map((provider,i) => <ProviderRow key={i} provider={provider} dashboard={true}/>)}
          </div>

          <div className="col-2">
            <div className="title">
              <h2>Derniers magasins inscrits</h2>
            </div>
            {shops.magasins.map((shop,i) => <ShopRow key={i * 10 } dashboard={true} shop={shop}/>)}
          </div>

        </section>
      </>
    )
  }

  return (
    <div className="page-container">

      <header className="page-header">
        <h1><Translate id="nav.home"/></h1>
      </header>

      <main className="page-content">

        {context.auth.dashboard && shops.magasins && providers.fournisseurs ?
          renderDashbord()
          :
          <div style={{ height: '80vh'}}>
            <Loader />
          </div>
        }
        
      </main>

    </div>
  );
};