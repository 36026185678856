import React , { useState } from 'react';
import { API_URL } from '../../../../actions/index';
import { Link } from "react-router-dom";

import { calcOfferPrice } from '../../../../utils/utils';

export default function OfferRow(props) {
  const { offer } = props;
  const [ imgError , setImgError ] = useState(false);
 
  function renderUpdatedLabel() {
    var updatedDate = new Date(offer.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className="updated-label"></div>
      );
    };
  };

  return (
    <Link to={"/offer-view/" + offer._id}>

      <div className="list-row">

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon product">
            {!imgError && <img
              src={`${API_URL}/static/products/${offer.gencod}.jpg`}
              onError={() => setImgError(true)}
              alt="product"/>}
          </div>
          <p>
            {offer?.company?.name}
          </p>
        </div>

        <div className="info offer">

          <p >
            {offer.product}
          </p>

          <p>
            {offer.segment}
          </p>

          <p>
            {offer.ref}
          </p>

          <p>
            {offer.gencod}
          </p>

          <p>
            {offer.packing}
          </p>

          <p className="price bold text-primary">
            {calcOfferPrice(offer)} €
          </p>

        </div>

      </div>

    </Link>
  );
};
