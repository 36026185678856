import React , { useEffect , useCallback , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , updateShowroomAction } from '../../../actions/providersActions';

import Input from '../../Partials/form_fields/Input';
import SwitchField from '../../Partials/form_fields/SwitchField';
import Loader from "../../Partials/Loader";

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";

import '../../../styles/StandView.scss';
import { MESSAGE_PROVIDER } from '../../../actions/types';

export default function ProviderView({ setActivePage, activePage, match }) {
  const [ context , dispatch ] = useGlobalContext();

  const provider = context?.providers?.provider;

  const { exhibitionsList, activeExhibition } = context?.exhibitions;
  const exhibitionUnit = exhibitionsList?.find((ex) => ex._id === activeExhibition)?.showroomType;

  const [ showroom , setShowroom ] = useState();
  const [ updatedShowroom , setUpdatedShowroom ] = useState();

  const [ number , setNumber ]= useState("");
  const [ isPlaced , setIsPlaced ] = useState(false);
  const [ isEditable , setIsEditable ] = useState(true);
  const [ notes , setNotes ] = useState();


  const getProvider = useCallback(() => {
    setActivePage('stands')
    getProviderAction( dispatch, match.params.id);
  }, [dispatch, match.params.id, setActivePage]);

  useEffect(() => {
    getProvider();
  }, [getProvider]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (provider && provider.showrooms && provider.showrooms.length > 0 ) {
      const lastShowroom = provider.showrooms[0];
      setShowroom(lastShowroom);
      if (lastShowroom.notes) {
        setNotes(lastShowroom.notes);
      };
      if (lastShowroom.number) {
        setNumber(lastShowroom.number);
      };
      if (lastShowroom.isPlaced) {
        setIsPlaced(lastShowroom.isPlaced);
      };
      setIsEditable(lastShowroom.isEditable);
    }
    // eslint-disable-next-line 
  }, [context.providers.provider])

  useEffect(() => {
    if (showroom && showroom._id) {
      const newShowroom = {
        _id: showroom._id,
        notes: notes,
        number: number,
        isPlaced: isPlaced,
        isEditable: isEditable,
      };
      setUpdatedShowroom(newShowroom);
    }
    // eslint-disable-next-line 
  }, [ notes , number , isPlaced, isEditable, showroom ])

  useEffect(() => {
    if (context.providers.message === 'Showroom successfully updated!') {
      successUpdate();
    }
    // eslint-disable-next-line 
  }, [context.providers.message])

  function successUpdate() {
    store.addNotification({
      message: "Stand mis à jour avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : MESSAGE_PROVIDER,
      payload : null
    });
    getProvider();
  }

  function subitShowroom() {
    if (updatedShowroom) {
      updateShowroomAction(dispatch , updatedShowroom)
    }
  }


  function renderStand() {
    return (
      <>
        <header className="page-header">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <h1>{provider.company.name}</h1>
        </header>
        <main className="page-content">
          <div className="btn-container">
            <Link to={"/stands"}>
              <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
            </Link>
            <div>
              {/* <button
                className="btn-edit edit-shoowroom"
                onClick={() => getShowroomLabelAction(dispatch , showroom._id , `livraison-${provider.company.name}${showroom.number ? `-stand-${showroom.number }`:''}` )}
                style={{ marginRight: 20 ,marginBottom: 10 , width : 200}}>
                <Translate id="btn.showroomLabel"/>
              </button> */}
              <Link to={`/showroom-edit/${showroom._id}/${activePage}`}>
                <button className="btn-edit edit-shoowroom" style={{ marginBottom: 10 , width : 140}}><Translate id="btn.editShowroom"/></button>
              </Link>
            </div>
          </div>

          <div className="row col-2">

            <div className="col-2">
              <div
                style={{ minHeight: 130}}
                className="card"
                >
                {provider?.company?.products &&
                  <div className="col-1">
                    <span className="bold"><Translate id="company.products"/></span><br/>
                    <div className="information-container">
                      <div className="col-1">
                        {provider?.company?.products?.map((item ,i ) => <div key={i} className="list-item selected">{item}</div>)}
                      </div>
                    </div>
                  </div>
                }
                <div className="col-2">
                  <Input
                    label={"Numéro de stand"}
                    value={number}
                    type={"text"}
                    className="input"
                    onChange={(value) => setNumber(value)}
                    />
                </div>
                <div className="col-1">
                  <SwitchField
                    checked={isPlaced}
                    handleChange={(val) => setIsPlaced(val)}>
                    <span className="bold">Le stand est placé sur le plan?</span>
                  </SwitchField>
                </div>
                <div className="col-1">
                  <SwitchField
                    checked={isEditable}
                    handleChange={(val) => setIsEditable(val)}>
                    <span className="bold">Le fournisseur peut modifier le stand</span>
                  </SwitchField>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div
                className="card notes"
                >
                <textarea
                  placeholder="Notes sur le stand"
                  style={{ minHeight:  214 }}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}/>
              </div>
            </div>

          </div>

          <div className="row showroom-info">

            <div className="col-shoowroom">
              <div className="col-container">
                <p className="nb-xl">{showroom.dimension}
                  <span className="unit">
                    {' '}
                    m
                    {exhibitionUnit === "square" ? <sup>2</sup> : null}
                  </span>
                </p>
              </div>
            </div>

            <div className="col-shoowroom border">
              <ul>

                <li className={`${showroom?.option?.power ? "" : "not-active"}`}>
                  <div>
                    <span className="bold">{showroom?.option?.power && showroom?.option?.powerQuantity > 0 ? showroom.option.powerQuantity : "0"} Kw</span>
                  </div>
                  <p className="bold">Éléctricié</p>
                </li>

                <li className={`${showroom?.option?.table > 0 ? "" : "not-active"}`}>
                  <div>
                    <span className="bold">{showroom?.option?.table > 0 ? showroom.option.table : "0"}</span>
                  </div>
                  <p className="bold">Tables (1,5m par 0,75m)</p>
                </li>

                <li className={`${showroom?.option?.chair > 0 ? "" : "not-active"}`}>
                  <div>
                    <span className="bold">{showroom?.option?.chair > 0 ? showroom.option.chair : "0"}</span>
                  </div>
                  <p className="bold">Chaises</p>
                </li>

                <li className={`${showroom?.option?.fridge > 0 ? "" : "not-active"}`}>
                  <div>
                    <span className="bold">{showroom?.option?.fridge > 0 ? showroom.option.fridge : "0"}</span>
                  </div>
                  <p className="bold">vitrines réfrigérées (0,6m)</p>
                </li>

                <li className={`${showroom?.option?.fridgeLength > 0 ? "" : "not-active"}`}>
                  <div>
                    <span className="bold">{showroom?.option?.fridgeLength > 0 ? showroom.option.fridgeLength : "0"} m</span>
                  </div>
                  <p className="bold">de vitrines réfrigérées</p>
                </li>
                {/* {option?.fridgeLength > 0 &&
            <p className="p-col-3">
              <span className="bold">Vitrines réfrigérées</span><br/>
              {option?.fridgeLength} m
            </p>
          }  */}
              </ul>
            </div>

          </div>

          {provider && provider.animations && provider.animations.length > 0 &&
            <div className="row col-2">
              {provider.animations.map(( anim , i ) => {
                if (anim.dimensionL) {
                  return (
                    <div key={i} className="col-2">
                      <div
                        style={{ minHeight: 100}}
                        className="card"
                        >
                        <div className="col-1">
                          <span className="bold">Espace animation</span><br/>
                          {anim.dimensionL * anim.dimensionP} m<sup>2</sup><br/>
                          {anim.description}
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={i} className="col-2">
                      <div
                        style={{ minHeight: 100}}
                        className="card"
                        >
                        <div className="col-1">
                          <span className="bold">Animation sur stand</span><br/>
                          {anim.description}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}

            </div>
          }
          
          <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
            <button
              className={`btn-save ${updatedShowroom ? "" : "disabled"}`}
              disabled={!updatedShowroom}
              onClick={() => subitShowroom()}
              >
                <Translate id="btn.save"/>
            </button>
          </div>

        </main>
      </>
    )
  }

  return (
    <div className="page-container">
      {showroom ?
        renderStand()
        :
        <div style={{ height: '100vh'}}>
          <Loader />
        </div>
      }
    </div>
  )
}