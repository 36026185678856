import React from 'react';
import searchIcon from "../../../assets/images/icons/search.svg";

export default function InputSearch({ search , setSearch , styles }) {
  return (
    <div className={`${styles['col-3']} ${styles.input}`} >
      <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
      <img className={styles['search-icon']} src={searchIcon} alt="search"/>
    </div>
  )
}
