import { getData , putData , postData } from './index';

import {
  GET_EXHIBITIONS_LIST,
  GET_EXHIBITION,
  ERROR_EXHIBITIONS,
  PUT_EXHIBITION,
  POST_EXHIBITION,
} from "./types"

export async function getExhibitionsListAction(dispatch){
  let url = "/exhibition"
  let exhibitionsList;
  await getData( ERROR_EXHIBITIONS, url, dispatch , true , false).then((response)=>{
    exhibitionsList = response.exhibitions;
  })
  dispatch({
    type : GET_EXHIBITIONS_LIST,
    payload : exhibitionsList
  })
}

export async function getExhibitionAction(dispatch , id){
  let url = `/exhibition/${id}`
  let exhibition;
  await getData( ERROR_EXHIBITIONS, url, dispatch , true , false).then((response)=>{
    exhibition = response.exhibition;
  })
  dispatch({
    type : GET_EXHIBITION,
    payload : exhibition
  })
}

export async function putExhibitionAction(dispatch , id, data ){
  let url = `/exhibition/${id}`
  let exhibition;
  await putData( PUT_EXHIBITION , ERROR_EXHIBITIONS, url, dispatch, data , true , false).then((response)=>{
    exhibition = response.exhibition;
  })
  dispatch({
    type : PUT_EXHIBITION,
    payload : exhibition
  })
}

export async function postExhibitionAction(dispatch , data ){
  let url = "/exhibition"
  let exhibition;
  await postData(POST_EXHIBITION , ERROR_EXHIBITIONS , url, dispatch , data, true , false ).then((response)=>{
    exhibition = response.exhibition;
  })
  dispatch({
    type : POST_EXHIBITION,
    payload : exhibition
  })
}




