import React , { useEffect , useState , useCallback } from 'react';
// import { Translate } from "react-localize-redux";

import UsersRow from './UserRow';
import Loader from "../../Partials/Loader";
import ExportExcel from '../../Partials/ExportExcel';

import { useGlobalContext } from '../../context/GlobalContext';
import { getUsersListAction , exportPresencesAction } from '../../../actions/usersActions';

import searchIcon from "../../../assets/images/icons/search.svg"
import { EXPORT_PRESENCES, GET_USER, MESSAGE_USERS } from '../../../actions/types';
import { getExhibitionsListAction } from '../../../actions/exhibitionsActions';

export default function UsersList({ setActivePage }) {
  const [ context, dispatch ] = useGlobalContext();
  const { activeExhibition  , exhibitionsList } = context.exhibitions; 
  const { usersList } = context.users;
  const [ search , setSearch ] = useState("");
  const [ filtredList , setFiltredList ] = useState([]);

  const exhibitionName = exhibitionsList?.find((e) => e._id === activeExhibition)?.name;

  const getUserList = useCallback(() => {
    getUsersListAction(dispatch);
    getExhibitionsListAction(dispatch);
    dispatch({
      type : MESSAGE_USERS,
      payload : ""
    });

    dispatch({
      type : GET_USER,
      payload : {}
    });

    dispatch({
      type : EXPORT_PRESENCES,
      payload : null
    });

  }, [dispatch]);

  useEffect(() => {
    if (activeExhibition) {
      setActivePage("users");
      getUserList();
      window.scrollTo(0, 0);
    }
  }, [activeExhibition, getUserList, setActivePage]);

  useEffect(() => {
    if (usersList) {
      setFiltredList(usersList.filter((user) => {
        if (user.profile && user.profile.lastName && user.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
          return user;
        } else if (user.profile && user.profile.firstName && user.profile.firstName.toLowerCase().includes(search.toLowerCase())) {
          return user;
        } else if (user.email && user.email.toLowerCase().includes(search.toLowerCase())) {
          return user;
        }
        else if (user.company && user.company.map((c) => c.name).join(',').toLowerCase().includes(search.toLowerCase())) {
          return user;
        }
        return null;
      }));
    }
  }, [search , usersList])

  function renderUserList() {
    if (!usersList) {
      return (
        <div style={{ height: '70vh'}}>
          <Loader />
        </div>
      )
    }
    if (filtredList && filtredList.length > 0) {
      return filtredList.filter(user => user._id !== context.auth.user._id).map((user, i) => <UsersRow
        key={user._id}
        user={user}
        exhibitionName={exhibitionName}
      />);
    }
    if (search && filtredList.length === 0) {
      return <span className="text-error">Aucun résultat ne correspond à votre recherche</span>;
    }
  }

  function renderExportBtn() {
    if (context.users.exportPresencesList && context.users.exportPresencesList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.users.exportPresencesList.fileName}
        dataToExport={context.users.exportPresencesList.dataToExport}/>
    } else {
        return <button
          onClick={() => exportPresencesAction(dispatch)}
          className="btn-edit export"
          style={{ width: 'auto' , height:36 , paddingLeft: 15 , paddingRight: 15 , marginTop: 5 }}>
          Exporter les présences
        </button>
    }
  }
  
  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Liste des utilisateurs</h1>
      </header>
      <main className="page-content">
        <div className="row space-between">
            <div className="col-3 input">
              <input placeholder="Recherche" value={search} onChange={(e) => setSearch(e.target.value)} />
              <img className={'search-icon'} src={searchIcon} alt="search"/>
            </div>
            {renderExportBtn()}
        </div>
        {renderUserList()}
      </main>
    </div>
  )
}