import React, { useState , useEffect , useCallback } from "react";
import history from "../../src/utils/history";
import { Router, Switch, Route } from "react-router-dom";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

import { useGlobalContext } from './context/GlobalContext';
import { getUserAction } from '../actions/authActions';

import Nav from "./Partials/Nav";

import UsersList from "./Pages/Users/UsersList";
import UserEdit from "./Pages/Users/UserEdit";

import HomePage from "./Pages/Home/HomePage";
import Login from "./Pages/Login/Login";
// import ResetPassword from "./Pages/Login/ResetPassword";

import ProvidersList from "./Pages/Providers/ProvidersList";
import ProviderView from "./Pages/Providers/ProviderView";
import ProviderEdit from "./Pages/Providers/ProviderEdit";
import ProviderContactsEdit from "./Pages/Providers/ProviderContactsEdit";

import StandsList from "./Pages/Stands/StandsList";
import StandView from "./Pages/Stands/StandView";

import ShowroomEdit from "./Pages/Providers/ShowroomEdit";
import ShopContactsEdit from "./Pages/Shops/ShopContactsEdit";

import ShopsList from './Pages/Shops/ShopsList';
import ShopView from './Pages/Shops/ShopView';
import ShopEdit from './Pages/Shops/ShopEdit';

import AnimationsList from "./Pages/Animations/AnimationsList";
import AnimationView from "./Pages/Animations/AnimationView";
import AnimationCreate from "./Pages/Animations/AnimationCreate";

import AssemblyList from "./Pages/Assembly/AssemblyList";
import AssemblyView from "./Pages/Assembly/AssemblyView";

import OffersList from "./Pages/Offers/OffersList";
import OfferView from "./Pages/Offers/OfferView";

import ReceptionList from "./Pages/Reception/ReceptionList";
import Badge from "./Pages/Reception/Badge";
import Scan from "./Pages/Reception/Scan";

import OrdersList from "./Pages/Orders/OrdersList";

import NotificationList from "./Pages/Notifications/NotificationsList"
import OrderCreate from "./Pages/Orders/OrderCreate";
import OrderView from "./Pages/Orders/OrderView";
import { ERROR_AUTH, SET_ACTIVE_EXHIBITION } from "../actions/types";
import ExhibitionsList from "./Pages/Exhibitions/ExhibitionsList/ExhibitionsList";
import EditExhibition from "./Pages/Exhibitions/EditExhibition/EditExhibition";
import { format } from "date-fns";


function MainRouter() {
  const [context,dispatch ] = useGlobalContext();
  const [ activePage , setActivePage ] = useState("login");
  const token = localStorage.getItem('token');

  const { auth } = context;
  const daysOptions = context.providers?.lists?.days;
  const { user } = auth;

  const getUser = useCallback(() => {
    getUserAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if(token) {
      getUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(auth.isAuth) {
      getUser();
    }
  }, [auth.isAuth, getUser]);

  useEffect(() => {
    if(user && daysOptions?.length > 0) {

      if (user.role === "Accueil" && activePage !== 'reception') {
        const today = new Date(format(new Date(),'yyyy/MM/dd')).getTime();
        const temp = daysOptions?.map((d) => Math.abs(today - new Date(format(new Date(d.value),'yyyy/MM/dd')).getTime()));
        const index = temp?.indexOf(Math.min(...temp)) >= 0? temp?.indexOf(Math.min(...temp)) : 0;
        const day = daysOptions[index]?.value;
        history.push(`/reception/${day}`);
      }
    }
  },[activePage, auth, daysOptions, user]);

  function logout(error) {
    history.push("/login");
    localStorage.removeItem('token');
    localStorage.removeItem('exhibition');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : SET_ACTIVE_EXHIBITION,
      payload : null
    });
    dispatch({
      type : ERROR_AUTH,
      payload : error
    });
  };

  useEffect(() => {
    if (!token) {
      history.push("/login");
      dispatch({
        type : ERROR_AUTH,
        payload : null,
      });
    }
  },[dispatch, token]);

  return (
    <div className="app-container">
      <ReactNotification />
      <Router history={history}>
        {activePage !== "login" ?
          <Nav
            activePage={activePage}
            setActivePage={setActivePage}
            logout={logout}
            history={history}
          />
          :
          null
        }
        <Switch >
          <Route exact path="/exhibition" render={
            (props) => 
              <ExhibitionsList
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/exhibition-create" render={
            (props) =>  <EditExhibition
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/exhibition/:id" render={
            (props) =>  <EditExhibition
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/providers" render={
            (props) => 
              <ProvidersList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          <Route exact path="/provider-view/:id" render={
            (props) =>  <ProviderView
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/provider-edit/:id" render={
            (props) =>  <ProviderEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/provider-create/" render={
            (props) =>  <ProviderEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/provider-contact-edit/:id" render={
            (props) =>  <ProviderContactsEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route path="/showroom-edit/:id/:activePage" render={
            (props) =>  <ShowroomEdit
              activePage={activePage}
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/stands" render={
            (props) => 
              <StandsList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          <Route exact path="/stand-view/:id" render={
            (props) =>  <StandView
              activePage={activePage}
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/shops">
            <ShopsList
              setActivePage={setActivePage} />
          </Route>
          <Route exact path="/shop-view/:id" render={
            (props) =>  <ShopView
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/shop-edit/:id" render={
            (props) =>  <ShopEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/shop-contact-edit/:id" render={
            (props) =>  <ShopContactsEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>
          <Route exact path="/animations" render={
            (props) => 
              <AnimationsList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          <Route exact path="/animation-view/:id" render={
            (props) => 
              <AnimationView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/create-animation/" render={
            (props) => 
              <AnimationCreate
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/assemblies" render={
            (props) => 
              <AssemblyList
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/offers" render={
            (props) => 
              <OffersList
                setActivePage={setActivePage}
                {...props}
              />}
            />
          
          <Route exact path="/offer-view/:id" render={
            (props) => 
              <OfferView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/orders" render={
            (props) => 
              <OrdersList
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/create-order" render={
            (props) => 
              <OrderCreate
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/order-view/:id" render={
            (props) => 
              <OrderView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/assembly-view/:id" render={
            (props) => 
              <AssemblyView
                setActivePage={setActivePage}
                {...props}
              />}
            />

          <Route exact path="/notifications" render={
            (props) => 
              <NotificationList
                setActivePage={setActivePage}
                {...props}
              />}
            />
            
          <Route exact path="/reception/:event" render={
            (props) => 
            <ReceptionList
              setActivePage={setActivePage}
              {...props}
              />}
            />

          <Route exact path="/badge/:id/:event" render={
            (props) =>  <Badge
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/scan/:event" render={
            (props) => 
            <Scan
              setActivePage={setActivePage}
              {...props}
              />}
            />

          <Route exact path="/users">
            <UsersList
              setActivePage={setActivePage} />
          </Route>

          <Route exact path="/user-edit/:id" render={
            (props) =>  <UserEdit
              setActivePage={setActivePage}
              {...props}
              />
          }/>

          <Route exact path="/">
            <HomePage
              setActivePage={setActivePage}/>
          </Route>

          <Route exact path="/login">
            <Login
              activePage={activePage}
              setActivePage={setActivePage}
              />
          </Route>

          {/* <Route exact path="/reset-password/:token" render={
            (props) => <ResetPassword
              activePage={activePage}
              setActivePage={setActivePage}
              {...props}
              />
            }/> */}

        </Switch>
      </Router>
    </div>
  );
}

export default MainRouter;
