import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from '../../context/GlobalContext';

import download  from "../../../assets/images/icons/download-badge.svg";
import contact  from "../../../assets/images/icons/contact.svg";
import star  from "../../../assets/images/icons/star.svg";
import starActive  from "../../../assets/images/icons/star_active.svg";
import starAdmin  from "../../../assets/images/icons/star_admin.svg";
import { getBadgeFileAction } from '../../../actions/receptionActions';

export default function UsersRow({ user , exhibitionName }) {

  const dispatch = useGlobalContext()[1]; 
  const { profile, role, _id , company , presence } = user;

  return (
    
      <div className="list-row">
          <Link to={"/user-edit/" + _id} className="user">
            <div className="name">
              <div className="icon">
                <img src={contact} alt="user"/>
              </div>
              {profile.lastName} {profile.firstName}
            </div>

            <div className="name">
              {role === "Member" &&
                <>
                  <img src={star} className="star" alt="icon-contact"/> 
                  <span>Membre</span>
                </>
              }
              {role === "Owner" &&
                <>
                  <img src={starActive} className="star" alt="icon-contact"/> 
                  <span>Référent</span>
                </>
              }
              {role === "Admin" &&
                <>
                  <img src={starAdmin} className="star" alt="icon-contact"/> 
                  <span>Administrateur</span>
                </>
              }
            </div>

            <div className="name">
              {company?.map(c => c.name).toString().split(',').join(' / ')}
            </div>

            <div className="name">
              {presence &&
                <>
                  {presence?.length} présence{presence?.length !== 1 && "s"}
                </>
              }
            </div>
            
          </Link>
          <button
            className="btn-edit circle"
            onClick={() =>  getBadgeFileAction(dispatch , user , exhibitionName  )}>
            <img src={download} alt="download"/>
          </button>
      </div>

  )
}
