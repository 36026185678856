import React from 'react';

import { calcOfferPrice } from '../../../../utils/utils';

export default function OfferRow(props) {
  const { offer } = props;

  return (

    <div className="list-row">

        {/* <div className="name">
          <p>
            {offer.company.name}
          </p>
        </div> */}

        <div className="info product-order">

          <p >
            {offer.product}
          </p>

          <p>
            {offer.segment}
          </p>

          <p>
            {offer.ref}
          </p>

          <p>
            {offer.gencod}
          </p>

          <p>
            {offer.packing}
          </p>


          <p>
            {offer.quantity}
          </p>

          <p className="price bold text-primary">
            {calcOfferPrice(offer)} €
          </p>

      </div>

    </div>
  );
};
