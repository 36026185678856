import {
  GET_NOTIFICATIONS_LIST,
  ERROR_NOTIFICATIONS,
  POST_NOTIFICATION,
  MESSAGE_NOTIFICATIONS
} from "../actions/types"

const initialState = {
  notificationsList: [],
  error: {},
  message: {},
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case POST_NOTIFICATION:
      return {...state,
        notificationsList : [...state.notificationsList , action.payload.notification],
        message : action.payload.message
      }
    case GET_NOTIFICATIONS_LIST:
      return {...state, notificationsList : action.payload}
    case ERROR_NOTIFICATIONS:
        return {...state, error: action.payload}
    case MESSAGE_NOTIFICATIONS:
      return {...state, message: action.payload}
    default:
      return {...state};
  }
}
