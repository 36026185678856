import React from 'react';
import { Link } from 'react-router-dom';

export default function ExhibitionsRow({ styles, exhibition }) {
  const updatedDate = new Date(exhibition.updatedAt);
  const today = new Date();
  const isUpatedLabel = today.getTime() < updatedDate.getTime() + 86400000;
  const startDate = new Date(exhibition.startDate);
  const endDate = new Date(exhibition.endDate);
  const date = new Intl.DateTimeFormat('fr', { dateStyle: 'short'}).format(startDate);

  function getStatus() {
    let status = {
      label: "En cours",
      className: "now",
    }
    if (today.getTime() > endDate.getTime()) {
      status = {
        label: "Fini",
        className: "finished",
      }
    }
    if (today.getTime() < startDate.getTime()) {
      status = {
        label: "A venir",
        className: "coming",
      }
    }
    return status;
  }

  return (
    <Link to={`/exhibition/${exhibition._id}`} className={styles.row}>
      {isUpatedLabel && <div className={styles['updated-label']} />}
      <div className={`${styles.col} ${styles.name}`}> 
        <p>{exhibition.name}</p>
      </div>
      <div className={`${styles.col}`}> 
        <p>{date}</p>
      </div>
      <div className={`${styles.col}`}> 
        <p>Nb fournisseur</p>
      </div>
      <div className={`${styles.col}`}> 
        <p>Nb magasins</p>
      </div>
      <div className={`${styles.col}`}> 
        <p className={`${styles.status} ${styles[getStatus().className]}`}>{getStatus().label}</p>
      </div>
    </Link>
  )
}
