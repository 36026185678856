import { getData , postData } from './index';

import {
  GET_NOTIFICATIONS_LIST,
  ERROR_NOTIFICATIONS,
  POST_NOTIFICATION
} from "./types"

export async function getNotificationsListAction(dispatch){
  let url = "/notification"
  let notificationsList;
  await getData( ERROR_NOTIFICATIONS, url, dispatch , true).then((response)=>{
    notificationsList = response.notifications
  })
  dispatch({
    type : GET_NOTIFICATIONS_LIST,
    payload : notificationsList
  })
}

export async function postNotificationAction(dispatch , obj){
  let url = "/notification"
  var notificationCreated;

  await postData(POST_NOTIFICATION , ERROR_NOTIFICATIONS , url, dispatch , obj, true ).then((response)=>{
    notificationCreated = response
  });
  
  dispatch({
    type : POST_NOTIFICATION,
    payload : notificationCreated
  });
};
