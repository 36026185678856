import {
  GET_ANIMATIONS_LIST,
  ERROR_ANIMATIONS,
  SCROLL_ON_ANIMATIONS,
  GET_ANIMATION,
  MESSAGE_ANIMATIONS,
  REMOVE_FILE,

} from "../actions/types"

const initialState = {
  animationsList: [],
  error: {},
  scrollAnimations: 0,
  message: {},
  animation: {}
};

export default function animationsReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_FILE: 
      return { 
        ...state,
        message: action.payload.message,
        animation: {
          ...state.animation,
          files: state.animation.files.filter(item => item !== action.payload.fileName)
        }
      }
    case MESSAGE_ANIMATIONS:
      return {...state, message: action.payload}
    case GET_ANIMATION:
      return {...state, animation : action.payload}
    case GET_ANIMATIONS_LIST:
      return {...state, animationsList : action.payload}
    case ERROR_ANIMATIONS:
        return {...state, error: action.payload}
    case SCROLL_ON_ANIMATIONS:
      return {...state, scrollAnimations: action.payload}
    default:
      return {...state};
  }
}
