import { getData, postData } from './index';

import {
  GET_OFFERS_LIST,
  ERROR_OFFERS,
  GET_OFFER,
  MESSAGE_OFFERS,
  UPLOAD_OFFERS
} from "./types"

export async function getOffersListAction(dispatch){
  let url = "/product"
  let offersList;
  await getData( ERROR_OFFERS, url, dispatch , true).then((response)=>{
    offersList = response.products
  })
  dispatch({
    type : GET_OFFERS_LIST,
    payload : offersList
  })
}

export async function getOfferAction(dispatch , id){
  let url = "/product/" + id 
  let offer;
  await getData( ERROR_OFFERS, url, dispatch , true).then((response)=>{
    offer = response.product
  })
  dispatch({
    type : GET_OFFER,
    payload : offer
  })
}


export async function uploadProductsAction(dispatch , data){
  const type = data.get('type');
  let url = "/product/upload"
  if(type === "image") {
    url = "/product/upload/images"
  }
  let productUpdated;
  await postData(UPLOAD_OFFERS , ERROR_OFFERS , url, dispatch , data, true ).then((response)=>{
   productUpdated = response;
  });
  if (productUpdated) {
    dispatch({
      type : MESSAGE_OFFERS,
      payload: "Les produits ont été mise à jour"
    });
  }
}
