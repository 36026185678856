import React , { useEffect , useCallback , useState } from 'react';
import { Translate } from "react-localize-redux";
import { Link , useHistory } from 'react-router-dom';
import Select from 'react-select';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProvidersListAction } from '../../../actions/providersActions';
import { getShopsListAction } from '../../../actions/shopsActions';

import selectStyle from '../../Partials/selectStyle';
import filterIcon from "../../../assets/images/icons/filters.svg";

import ProductsRow from "./Orders_Partials/ProductsRow";

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import { getProductsAction , postOrderAction } from '../../../actions/ordersActions';

export default function OrderCreate(props) {
  props.setActivePage("orders");

  var history = useHistory();

  const [ context , dispatch ] = useGlobalContext();

  const providersList = context?.providers?.providersList || [];
  const shopsList = context?.shops?.shopsList || [];
  const productsList = context?.orders?.productsList | [];

  const [ providersOptions , setProvidersOptions ] = useState([]);
  const [ selectedProvider , setSelectedProvider ] = useState();
  const [ shopsOptions , setShopsOptions ] = useState([]);
  const [ selectedShop , setSelectedShop ] = useState();
  const [ selectedDomain , setSelectedDomain ] = useState();
  const [ filtredList , setFiltredList ] = useState();

  const [ products , setProducts ] = useState([]);

  const [ sortByAlpha , setSortByAlpha ] = useState(false);
  const [ sortByRef , setSortByRef ] = useState(false);
  const [ sortByGencode , setSortByGencode ] = useState(false);
  const [ sortByPack , setSortByPack ] = useState(false);
  const [ sortByTotal , setSortByTotal ] = useState(false);

  const getProvidersList = useCallback(() => {
    getProvidersListAction(dispatch);
    getProductsAction(dispatch);
    getShopsListAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
      getProvidersList();
  }, [getProvidersList]);

  useEffect(() => {

    if (providersList) {
      setProvidersOptions(providersList.map(item => ({ label: item.name , value: item._id })));
    }

    if (shopsList?.length > 0) {
      setShopsOptions(shopsList.map(item => ({ label: item.name , value: item })));
    }

    if (productsList) {
      setFiltredList(productsList);
    }

  }, [providersList , shopsList , productsList]);

  useEffect(() => {
    if (filtredList) {
      if (sortByAlpha) {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.company.name > b.company.name)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByAlpha]);


  useEffect(() => {
    if (filtredList) {
      if (sortByRef) {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.ref > b.ref)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByRef]);

  useEffect(() => {
    if (filtredList) {
      if (sortByGencode) {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.gencod > b.gencod)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByGencode]);

  useEffect(() => {
    if (filtredList) {
      if (sortByPack) {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b ) => ( parseInt(a.packing.split(" ")[0]) > parseInt(b.packing.split(" ")[0]))? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByPack]);

  useEffect(() => {
    if (filtredList) {
      if (sortByTotal) {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1));
      } else {
        setFiltredList(filtredList.sort((a ,b )=> (a.price > b.price)? 1:-1).reverse());
      };
    };
    // eslint-disable-next-line
  }, [sortByTotal]);

  useEffect(() => {

    if (context.orders.message === "order saved") {
      successCreated();
      history.push("/orders")
    }
    // eslint-disable-next-line
  }, [context.orders.message])

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);


  function successCreated() {
    store.addNotification({
      message: "La commande a été créé avec succès!",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : 'MESSAGE_ORDERS',
      payload : {}
    });
  }

  function handleChangeProducts(id , qty ) {
    var productsList = [...products.filter(item => item !== id)];
    for (let i = 0; i < qty; i++) {
      productsList.push(id);
    }
    setProducts(productsList)
  }

  function submit() {
    var data = {
      user: context.auth.user._id,
      company: selectedShop.value._id,
      domain: selectedDomain.value,
      products: products
    }
 
    postOrderAction(dispatch , data );

  }

  function renderProductsList() {
    if (filtredList) {
      if (filtredList.length > 0) {
        return filtredList.filter(item => item.company._id === selectedProvider.value).map(( product , i) => <ProductsRow key={i} handleChangeProducts={handleChangeProducts} product={product}/>);
      } else if (filtredList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  return (
    <div className="page-container">
      <header className="page-header">

        <div className="icon">
          <img src={providerIcon} alt="icon-provider"/>
        </div>

        <h1>
          Création d'une commande {selectedProvider &&  `pour ${selectedProvider.label}`}
        </h1>

        </header>

        <main className="page-content">

          <Link to={"/orders"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ marginBottom: 10, width : 100}}><Translate id="btn.back"/></button>
          </Link>

          <div className="row space-between">
            <div className="col-3">
              <Select
                options={shopsOptions}
                onChange={(val) => setSelectedShop(val)}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un magasin"
                />
            </div>
            <div className="col-3">
              {selectedShop && selectedShop.value && selectedShop.value.domains && <Select
                options={selectedShop.value.domains.map(item => ({ label: item , value: item }))}
                onChange={(val) => setSelectedDomain(val)}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un lieu de vente"
                />
              }
            </div>
            <div className="col-3">
              {selectedDomain && <Select
                options={providersOptions}
                onChange={(val) => setSelectedProvider(val)}
                isMulti={false}
                styles={selectStyle}
                placeholder="Sélectionner un fournisseur"
                />
              }
            </div>

            {products.length > 0 &&
              <button
                onClick={() => submit()}
                className="btn-save"
                >Valider
              </button>
            }

          </div>

          {selectedProvider && selectedProvider.value &&

            <div className="filters">

              <div
                className="filter"
                onClick={() => setSortByAlpha(!sortByAlpha)}>
                <span>Nom du fournisseur</span>
                <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByAlpha(!sortByAlpha)}>
                <span>Segment</span>
                <img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByRef(!sortByRef)}>
                <span>Référence</span>
                <img src={filterIcon} className={sortByRef? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                onClick={() => setSortByGencode(!sortByGencode)}>
                <span>Gencod</span>
                <img src={filterIcon} className={sortByGencode? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"

                onClick={() => setSortByPack(!sortByPack)}>
                <span>Colistage</span>
                <img src={filterIcon} className={sortByPack? "rotate180": ""} alt="filter"/>
              </div>

              <div
                className="filter"
                style={{ minWidth: '250px'}}
                onClick={() => setSortByTotal(!sortByTotal)}>
                <span>Prix net salon HT/unité</span>
                <img src={filterIcon} className={sortByTotal? "rotate180": ""} alt="filter"/>
              </div>

              <div className="filter">
                <span>Nbre de colis</span>
              </div>


            </div>
          }

        {selectedProvider &&  selectedProvider.value && renderProductsList()}

        </main>
    </div>
  );
  
};