import React from "react";
import { Link } from "react-router-dom";

import providerIcon  from "../../../../assets/images/icons/provider_icon.svg";
import placed  from "../../../../assets/images/icons/placed.svg";
import elec  from "../../../../assets/images/icons/elec.svg";
import { useGlobalContext } from "../../../context/GlobalContext";
// import water  from "../../../../assets/images/icons/water.svg";
// import evier  from "../../../../assets/images/icons/evier.svg";
// import carpet  from "../../../../assets/images/icons/carpet.svg";

export default function StandRow(props) {
  const { provider } = props;
  const [ context ] = useGlobalContext();
  const { activeExhibition } = context.exhibitions;
  const exhibition = context.exhibitions?.exhibitionsList?.find((ex) => ex._id === activeExhibition); 
  const isSquare = exhibition?.showroomType !== "linear";

  function renderUpdatedLabel() { 
    if (provider.showroomUpdatedAt) {
      var updatedDate = new Date(provider.showroomUpdatedAt);
      var today = new Date()
      if ( today.getTime() < updatedDate.getTime() + 86400000) {
        return (
          <div className="updated-label"></div>
        );
      };
    }
  }
  
  return (
    <Link to={`/stand-view/${provider._id}`}>
      <div className="list-row">
        {renderUpdatedLabel()}  
        <div className="name">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <p>
            {provider.name}
          </p>
        </div>
        <div className="info stand">
          {provider.products ?
            <div className="univers">
              {provider.products.map((item , i )=> <React.Fragment key={i}><span>{item}</span> </React.Fragment> )}
            </div>
            :
            <div className="not-active univers">
            </div>
          }
          {provider.showroomNumber ?
            <div className="code-number">
              <span>{provider.showroomNumber}</span>
            </div>
            :
            <div className="not-active code-number">
              <span>---</span>
            </div>
          }

          {provider.showroomDimension && provider.showroomDimension > 0 ?
            <div>
              <span>{provider.showroomDimension} m{isSquare &&<sup>2</sup>}</span>
            </div>
            :
            <div className="not-active">
              <span>0 m{isSquare &&<sup>2</sup>}</span>
            </div>
          }
          {provider?.showroomOption?.table  ?
            <div>
              <span>{provider.showroomOption.table}</span>
            </div>
            :
            <div className="not-active">
              <span>0</span>
            </div>
          }
          {provider?.showroomOption?.fridge  ?
            <div>
              <span>{provider.showroomOption.fridge}</span>
            </div>
            :
            <div className="not-active">
              <span>0</span>
            </div>
          }
          {provider.showroomPlaced ?
            <div className="picto">
              <img src={placed} alt="icon-contact"/>
            </div>
          :
            <div className="picto not-active">
              <img src={placed} alt="icon-contact"/>
            </div>
          }
          <div className="picto-stand-list">
            {/* {provider.showroomOption && provider.showroomOption.water ?
              <div className="picto">
                <img src={water} alt="icon-contact"/>
              </div>
              :
              <div className="picto not-active">
                <img src={water} alt="icon-contact"/>
              </div>
            } */}
            {provider.showroomOption && provider.showroomOption.power ?
              <div className="picto">
                <img src={elec} alt="icon-contact"/>
              </div>
              :
              <div className="picto not-active">
                <img src={elec} alt="icon-contact"/>
              </div>
            }
            {/* {provider.showroomOption && provider.showroomOption.waterType === "alimentation eau + évier" ?
              <div className="picto">
                <img src={evier} alt="icon-contact"/>
              </div>
              :
              <div className="picto not-active">
                <img src={evier} alt="icon-contact"/>
              </div>
            } */}
            {/* {provider.showroomOption.carpet && !provider.showroomOption.customCarpet ?
              <div style={{ marginLeft: 12 }} className="picto">
                <img src={carpet} alt="icon-contact"/>
              </div>
              :
              <div style={{ marginLeft: 12 }} className="picto not-active">
                <img src={carpet} alt="icon-contact"/>
              </div>
            } */}
          </div>
        </div>
      </div>
    </Link>
  )
}
