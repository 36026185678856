import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import { translateDate } from '../../utils/utils';
import { store } from 'react-notifications-component';
import { invitProviderAction, resetProviderAction } from '../../actions/providersActions';
import { invitShopAction } from '../../actions/shopsActions';

export default function Company({ company , contacts , root , dispatch }) {


const mainUser = contacts?.find(u =>  u._id === company.mainUser);
  function alertResetCompany() {
    var answer = window.confirm("Souhaitez-vous réinitialiser le fournisseur ?")
      if (answer) {
        resetProviderAction(dispatch , company._id );
      }
  }
  async function invitProvider(id) {
   const res = await invitProviderAction(dispatch, id);
   if (res) {
    store.addNotification({
      message: "L'invitation a été envoyée",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
   }
  }

  async function invitShop(id) {
    const res = await invitShopAction(dispatch, id);
    if (res) {
     store.addNotification({
       message: "L'invitation a été envoyée",
       type: "success",
       insert: "top",
       container: "bottom-right",
       animationIn: ["animated", "fadeIn"],
       animationOut: ["animated", "fadeOut"],
       dismiss: {
         duration: 3000,
         onScreen: true
       }
     });
    }
   }
   
  
  function renderProviderCompany() {
    return (
      <div className="card info">

        <div className="information-container">
          <p className="p-col-3">
            <span className="bold"><Translate id="company.social"/></span><br/>
            {company.name}
          </p>
          <p className="p-col-3">
            <span className="bold"><Translate id="company.code"/></span><br/>
            {company.code}
          </p>
          <p className="p-col-3">
            <span className="bold"><Translate id="company.iddigit"/></span><br/>
            {company.iddigit}
          </p>
          {company.wholesaler?.name &&
            <p className="p-col-3">
              <span className="bold"><Translate id="company.wholesaler"/></span><br/>
              {company.wholesaler?.name}
            </p>
          }
          {mainUser?.profile &&
            <p className="p-col-3">
              <span className="bold"><Translate id="company.mainUser"/></span><br/>
              {mainUser.profile.firstName} {mainUser.profile.lastName}
            </p>
          }
          {mainUser?.profile &&
            <p className="p-col-3">
              <button
                style={{ padding: "5px 10px" }}
                className="btn-edit"
                onClick={() => invitProvider(mainUser._id)}
              >
                Inviter {mainUser.profile.firstName} {mainUser.profile.lastName}
              </button>
            </p>
          }
        </div>

        <div className="col-1">
          <span className="bold"><Translate id="company.domains"/></span><br/>
          <div className="information-container">
            <div className="col-1">
              {company?.domains?.map((item ,i ) => <div key={i} className="list-item selected">{item.label || item}</div>)}
            </div>
          </div>
        </div>
        <div className="col-1">
          <span className="bold"><Translate id="company.products"/></span><br/>
          <div className="information-container">
            <div className="col-1">
              {company?.products?.map((item ,i ) => <div key={i} className="list-item selected">{item.label || item}</div>)}
            </div>
          </div>
        </div>
        
        <div className="col-1">
          <span className="bold"><Translate id="company.insurance"/></span><br/>
          <div className="information-container">
            <p className="p-col-3 contact border">
              {company?.insurance?.company}
            </p>
            <p className="p-col-3 contact">
              {company?.insurance?.number}
            </p>
          </div>
        </div>
    
        <p className="col-1" style={{ fontSize: 12 , opacity: 0.58 }} >Modifié le {translateDate(company.updatedAt, 'fr')}</p> 
      </div>
    )
  };

  function renderShopCompany() {
    return (
      <div className="card">

        <div className="information-container">
          <p className="p-col-3">
            <span className="bold"><Translate id="company.social"/></span><br/>
            {company.name}
          </p>
          <p className="p-col-3">
            <span className="bold"><Translate id="company.acdlec"/></span><br/>
            {company.acdlec}
          </p>
          <p className="p-col-3">
            <span className="bold"><Translate id="company.central"/></span><br/>
            {company.centrale}
          </p>
          <p className="p-col-3">
            <span className="bold"><Translate id="company.city"/></span><br/>
            {company.location.city}
          </p>
          {mainUser?.profile &&
            <p className="p-col-3">
              <span className="bold"><Translate id="company.mainUser"/></span><br/>
              {mainUser.profile.firstName} {mainUser.profile.lastName}
            </p>
          }
          {mainUser?.profile &&
            <p className="p-col-3">
              <button
                style={{ padding: "5px 10px" }}
                className="btn-edit"
                onClick={() => invitShop(mainUser._id)}
              >
                Inviter {mainUser.profile.firstName} {mainUser.profile.lastName}
              </button>
            </p>
          }
          <div className="col-1">
          <span className="bold"><Translate id="company.activities"/></span><br/>
          <div className="information-container">
            <div className="col-1">
              {company.domains.map((item ,i ) => <div key={i} className="list-item selected">{item.label || item}</div>)}
            </div>
          </div>
          </div>
        </div>
        <span style={{ fontSize: 12 , opacity: 0.58 }} >Modifié le {translateDate(company.updatedAt, 'fr')}</span> 
      </div>
    )
  }
  
  return (
    <>
      <div className="title">
        <h2><Translate id="company.title"/></h2>
        <Link to={"/"+ root + "-edit/" + company._id} className="btn-edit"><Translate id="btn.edit"/></Link>
        {root === "provider" && <button style={{ paddingLeft: 15 , paddingRight: 15  }} className="btn-edit" onClick={() => alertResetCompany()}>Réinitialiser</button>}
      </div>
      {root === "provider"?
        renderProviderCompany()
        :
        renderShopCompany()
      }
    </>
  )
}
