import React , { useEffect , useState , useCallback } from 'react';
import { Translate } from "react-localize-redux";
import { Link , useParams } from 'react-router-dom';
import _ from  'lodash';
import { store } from 'react-notifications-component';

import { useGlobalContext } from '../../context/GlobalContext';
import { getProviderAction , createShowroomAction , updateShowroomAction } from '../../../actions/providersActions';

import ShowroomOptions from './Providers_Partials/ShowroomOptions';

import '../../../styles/Edit.scss';

import providerIcon  from "../../../assets/images/icons/provider_icon.svg";
import { MESSAGE_PROVIDER } from '../../../actions/types';

export default function ShowroomEdit({ setActivePage, match }) {

  const params = useParams();

  const [ context , dispatch ] = useGlobalContext();

  useEffect(()=> {
    setActivePage("providers");
  },[setActivePage]);

  const [errors, setErrors] = useState(null);
  const [ showroomForm , setShowroomForm ] = useState({});

  const { exhibitionsList, activeExhibition } = context?.exhibitions;
  const exhibition = exhibitionsList?.find((ex) => ex._id === activeExhibition); 

  const provider = context?.providers?.provider;
  const company = provider?.company?._id;
  const user = context?.auth?.user?._id;

  const getProvider = useCallback(() => {
    getProviderAction( dispatch, match.params.id);
  }, [dispatch, match.params.id]);


  function handleChangeShowroom(obj) {
    const data = {...obj}
    if(obj?.option?.power === false && showroomForm.option.powerQuantity > 0) {
      data.option.powerQuantity = 0; 
    }
    setShowroomForm({...showroomForm, ...obj})
  }

  function subitShowroom() {
    if (!showroomForm.dimension && exhibition?.displayShowroomSize) {
      return setErrors({ dimension: "la dimension est requise"})
    }
    if (!showroomForm?._id) {
      createShowroomAction(dispatch , showroomForm);
    } else {
      updateShowroomAction(dispatch , showroomForm);
    }
  }

  function successNotif(message) {
    store.addNotification({
      message,
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    dispatch({
      type : MESSAGE_PROVIDER,
      payload : null
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (provider.showrooms && provider.showrooms.length > 0) {
      const lastShowroom = _.cloneDeep(provider.showrooms[0]);
      setShowroomForm(lastShowroom);
    } else {
      setShowroomForm({
        company,
        user,
        isPlaced: false,
        dimension: 0,
        option: {
          table: 0,
          fridge:0,
          power: false,
          powerQuantity: 0,
          standiste: {
            name:"",
            phone:"",
            email:""
          }
        }
      });
    }
     // eslint-disable-next-line
  }, [provider]);

  useEffect(() => {
    if(!provider.company) {
      getProvider();
    }
    // eslint-disable-next-line
  }, [getProvider]);

  useEffect(() => {
    if (context?.providers?.message === 'Showroom successfully updated!') {
      successNotif("Stand a été créé avec succès!");
    }
    if (context?.providers?.message === 'showroom saved') {
      successNotif("Stand mis à jour avec succès!");
    }
    // eslint-disable-next-line 
  }, [context.providers.message])


  return (
    <div className="page-container">
      {provider?.company &&
        <>
        <header className="page-header">
          <div className="icon">
            <img src={providerIcon} alt="icon-provider"/>
          </div>
          <h1>{provider.company.name}</h1>
        </header>
        <main className="page-content">
          <Link to={params.activePage !== "stands" ? "/provider-view/" + company : "/stands"} className="btn-edit mb-10">
            <button className="btn-edit" style={{ width : 100}}><Translate id="btn.back"/></button>
          </Link>
          <ShowroomOptions
            showroom={showroomForm}
            handleChange={handleChangeShowroom}
            errors={errors}
          />
          <div style={{ margin: 20 , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>
            <button
              className={`btn-save ${!errors? "" : "disabled"}`}
              disabled={errors}
              onClick={() => subitShowroom()}
              >
                <Translate id="btn.save"/>
            </button>
          </div>
        </main>
      </>
      }
    </div>
  )
}
