import React, { useState } from 'react';
import trash from '../../../assets/images/icons/trash.svg';
import Input from '../form_fields/Input';

const defaultActivity = {
  label: "",
  code: "",
}

export default function SelectActivities({label, activeList, onChange }) {
  const [addActivity, setAddActivity] = useState();
  function handleChangeList(value) {
    var array = [...activeList];
    if (value === array.find(item => item.label === value.label)) {
      array = array.filter(item => item.label !== value.label);
      onChange(array);
    } else {
      setAddActivity();
      onChange([...array , value]);
    }
  }
  return (
    <>
      <span className="bold" style={{ fontSize: 14 }}>{label}</span>
      <div className="list-container">
        {activeList.map((item ,i) => {
          return (
            <div
              key={i}
              className={"list-item selected btn"}
              >
              {item.label}
              <button
                onClick={() => handleChangeList(item)}
                className="delete"
              >
                <img src={trash} alt="delete" />
              </button>
            </div>
          )
        })}
      </div>
      {addActivity ?
        <>
          <div className="row">
            <div className="col-3">
              <Input
                label="Type"
                value={addActivity.label}
                type={"text"}
                className="input"
                onChange={(value) => setAddActivity({...addActivity, label: value.toUpperCase()})}
                />
            </div>     

            <div className="col-3">
              <Input
                label={"Code"}
                value={addActivity.code}
                type={"text"}
                className="input"
                onChange={(value) => setAddActivity({...addActivity, code: value})}
                />
            </div>
          </div>
          {addActivity.label && addActivity.code &&
            <button
              className="btn-edit"
              style={{ fontSize: 14, marginBottom: 10 , width: 160 }}
              onClick={() => handleChangeList(addActivity)}
            >
              Ajouter l'activité
            </button>
          }
          </>
          :
          <button
          className="btn-edit"
          style={{ fontSize: 14, marginBottom: 10 , width: 160 }}
          onClick={() => setAddActivity(defaultActivity)}
        >
          Ajouter une activité
        </button>
      }
    </>
  )
}
