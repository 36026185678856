import React, { useState , useEffect } from 'react';
import NumberFormat from 'react-number-format';

export default function Input(props) {
  const [ error , setError ] = useState(false);
  const [ value , setValue ] = useState(null)

  const formats = {
    'phone' : /^(\d{2}\s){4}\d{2}$/,
    'email': /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    'leclercEmail': /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i
  }

  useEffect(() => {
    if(props.format && value){
      let regex = formats[props.format];
      if(!regex.test(value)){
        setError(true)
      } else{
        setError(false)
      }
    }
  },[value , formats , props.format ]);

  function handleChange(val) {
    props.onChange(val);
    setValue(val)
  }

  return (
    <div className={props.className}>
      <label className="bold">{props.label}</label>
        {props.maskNumber ?
          <NumberFormat
            className={error ? "error" : ""}
            value={props.value || '' }
            type={props.format === "phone" ? "tel" : props?.type}
            format={props.maskNumber}
            onChange={(e) => handleChange(e.target.value)}
          />
          :
          <input
            className={error ? "error" : ""}
            type={props.type}
            style={props.style || {} }
            value={props.value || '' }
            onChange={(e) => handleChange(e.target.value)}/>
        }
        <span className="text-error">{props.error ? props.error : ""}</span>
    </div>
  )
}
